<template>
    <div class="settings">
        <div id="snackbar"></div>
        <div v-if="panelEdit" class="toolPanel">
            <div class="cc">
                <div class="x" v-on:click="hideEdit">X</div>
                <div v-if="selected.niveau != 'video' && selected.niveau != 'Pvideo'">
                    <div class="ccTitle">Edit {{selected.niveau}} image</div>
                    <div class="ccChild">
                        <span>Current Image:</span>
                        <img height="200" :src="'https://api.victory-partners.ma/victory%20api/upload/'+selected.image" alt="">
                    </div>
                    <div class="ccChild">
                        <span>Image:</span>
                        <div class="import" @click="file.name!=null?file={}:null">
                            <span>
                                <i v-if="file.name==null" style="margin: auto;font-size: 25px;" class="fa fa-upload"></i>
                                <i v-if="file.name!=null" style="margin: auto;font-size: 25px;color:red" class="fa fa-close"></i>
                                <div>{{file.name!=null?file.name:'No File chosen'}}</div>
                            </span>
                            <input @change="chooseImg" type="file" :style="file.name!=null?'width:0':''" :disabled="file.name!=null?true:false" :class="selectedImg?'enabled':''" id="addImage" accept="image/png, image/jpeg">
                        </div>
                    </div>
                    <div v-if="editError" style="color:yellow;text-align:center">Image required!</div>
                </div>
                <div v-if="selected.niveau == 'video' || selected.niveau == 'Pvideo'">
                    <div class="ccTitle">Edit {{selected.niveau}} démonstration Youtube Lien</div>
                    <div class="ccChild" style="width:50%">
                        <span>Current Video:</span>
                        <span>
                            https://www.youtube.com/embed/<input type="text" id="lien" :value="selected.image.split('/')[selected.image.split('/').length-1]">
                        </span>
                    </div>
                    <div v-if="editError" style="color:yellow;text-align:center">Lien required!</div>
                </div>
                <div style="text-align:center"><button v-on:click="edit" class="btn edit"><i class="fa fa-edit"></i></button></div>
            </div>
        </div>
        <div class="TableTitle"><img src="@/assets/icons/settings.png" width="50" alt=""> Paramétres d'annonces</div>
        <div class="toolbox">
            <div style="margin:auto; text-align:center">
                <button v-on:click="showEdit" :disabled="selected.id?false:true" :class="!selected.id?'btn edit enable':'btn edit'"><i class="fa fa-edit"></i></button>
            </div>
            <div class="searchbar">
                <img src="@/assets/icons/search.png" width="25" alt="">
                <input type="text" id="search" v-on:keyup="searchh" placeholder="search">
            </div>
        </div>
        <div class="customersCnt">
            <table id="customers">
                <thead>
                    <tr style="cursor:initial">
                        <th>Niveau</th>
                        <th>Image</th>
                        <th style="padding:0;width:15px;min-width: 0px;"></th>
                    </tr>
                </thead>
                <div class="tablesScroll">
                    <tr v-for="d in data" :key="d.id" v-on:click="select(d)" :id="d.id">
                        <th v-if="d.niveau == 'video'" style="text-align:center;display: table-header-group;">vidéo démonstration</th>
                        <th v-if="d.niveau == 'Pvideo'" style="text-align:center;display: table-header-group;">vidéo présentation</th>
                        <th v-if="d.niveau == 'home image'" style="text-align:center;display: table-header-group;">page d'accueil annonce</th>
                        <td v-if="d.niveau == 'home image'" style="text-align:center"><img style="text-align:center; padding: 0 8px;height:100px" :src="d.image?'https://api.victory-partners.ma/victory%20api/upload/'+d.image:'../assets/Q.jpg'" alt=""></td>
                        <td v-if="d.niveau == 'video'" style="text-align:center">{{d.niveau?d.image:'null'}}</td>
                        <td v-if="d.niveau == 'Pvideo'" style="text-align:center">{{d.niveau?d.image:'null'}}</td>
                        <td v-if="d.niveau != 'video' && d.niveau != 'Pvideo' && d.niveau != 'home image'" style="text-align:center">{{d.niveau?d.niveau:'null'}}</td>
                        <td v-if="d.niveau != 'video' && d.niveau != 'Pvideo' && d.niveau != 'home image'" style="text-align:center"><img style="text-align:center; padding: 0 8px;height:100px" :src="d.image?'https://api.victory-partners.ma/victory%20api/upload/'+d.image:'../assets/Q.jpg'" alt=""></td> 
                    </tr>
                </div>
            </table>
        </div>
    </div>
</template>

<script>
import { Rest } from '@/providers/rest';
import axios from 'axios'

export default {
    name:'Clients',
    data() {
        return {
            panelEdit: false,
            selected:{},
            editError: false,
            file: {},
        }
    },
    props: ['data'],
    methods:{
        hideEdit:function(){
            this.panelEdit = false;
            this.file = {};
        },
        showEdit:function(){
            this.panelEdit = true;
        },
        select(d){
            if(this.selected.id == d.id){
                this.selected = {};
                document.getElementById(d.id).className = "";
            }
            else{
                this.selected = d;
                for(const selected of document.getElementsByClassName('selected')){
                    selected.className = "";
                }
                document.getElementById(d.id).className = "selected";
            }
        },
        edit(){
            this.$parent.load = true;
            const formData = new FormData();
            formData.append('file', this.file);
            if(this.selected.niveau != "video" && this.selected.niveau != "Pvideo"){
                if(!this.file.name){
                    this.addError = true;
                    this.$parent.load = false;
                }
                else{
                    const imagePath = "images/" + this.file.name;
                    const data = {
                        type: 'annonce',
                        id: this.selected.id,
                        image: imagePath
                    }
                    axios.post('https://api.victory-partners.ma/victory%20api/upload.php',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    ).then(rr=>{
                        new Rest().updateAdmin(data).then(r=>{
                            this.hideEdit()
                            if(r.data.success){
                                this.snackBar(r.data.msg);
                                this.select(this.selected);
                                this.$parent.load = false;
                                this.$parent.loadd();
                            }
                            else{
                                alert(r.data.msg)
                                this.$parent.load = false;
                            }
                        }).catch(e=>{alert(e);this.$parent.load = false;});
                    }).catch(()=>{
                            alert('faild to upload image');
                            this.$parent.load = false;
                        });
                    
                }
            }
            else{
                const lien = document.getElementById('lien').value;
                if(!lien){
                    this.addError = true;
                    this.$parent.load = false;
                }
                else{
                    const data = {
                        type: 'annonce',
                        id: this.selected.id,
                        image: 'https://www.youtube.com/embed/' + lien
                    }
                    new Rest().updateAdmin(data).then(r=>{
                        this.hideEdit()
                        if(r.data.success){
                            this.snackBar(r.data.msg);
                            this.select(this.selected);
                            this.$parent.load = false;
                            this.$parent.loadd();
                        }
                        else{
                            alert(r.data.msg)
                            this.$parent.load = false;
                        }
                    }).catch(e=>{alert(e);this.$parent.load = false;});
                }
            }
            
        },
        snackBar: function(msg) {
            const x = document.getElementById("snackbar");
            if(x){
                x.className = "show";
                x.innerText = msg;
                setTimeout(function(){ if(x){ x.className = x.className.replace("show", ""); }}, 5000);
            }
        },
        searchh(){
            const txt = document.getElementById('search').value.toLowerCase().trim();
            const tr = document.getElementsByTagName('tr');
            for(const t of tr){
                if(t.innerText.toLowerCase().indexOf(txt)==-1){
                    t.style.display = "none"; 
                }
                else{
                    t.style.display = "inline-table";
                } 
            }
        },
        chooseImg(event){
            console.log(event.target.files[0].type)
            if(event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png'){
                event.target.value = '';
                alert('Accept .PNG and .JPEG files only')
            }
            else{
                this.file = event.target.files[0];
            }
        },
    }
}
</script>


<style>
.TableTitle{
    display: flex;
    width: fit-content;
    align-items: center;
    margin: auto;
    padding-top: 20px;
    font-size: 30px;
    font-weight: bold;
}
.TableTitle img{
    margin-right: 10px;
}
.settings{
    position: relative;
    height: 100%;
}
.customersCnt{
    height: calc(100% - 133px);
    max-height: calc(100% - 133px);

}
.tablesScroll{
    overflow-y: scroll;
    height: calc(100vh - 268px);
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 99.9%;
  margin: auto;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
    max-width: 30px;
    overflow-wrap: break-word;
    min-width: 30px;
}
tr{
    display: inline-table;
    width: 99.9%;
    cursor: pointer;
}
thead tr{
    display: inline-table !important;
}

.import{
        height: fit-content;
        background-color: #ffffffb0;
        border-radius: 20px;
        border: dashed 1px;
        position: relative;
        padding: 10px;
        width: 200px;
        height: 70px;
    }
    .import span{
        position: absolute;
        width: 100%;
        left: 0;
    }
    .import input{
        width:100%;
        padding:0;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        padding: 15px 0;
    }
#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
text-align: center;
  background-color: #bb1013;
  color: white;
}
.toolbox{
    padding: 10px;
    background-color: #909090;
    display: flex;
}
.searchbar{
    margin-left: auto;
    display: flex;
    align-items: center;
}
.searchbar input{
    border-radius: 20px;
    padding: 5px;
    font-size: 15px;
}
.searchbar input::placeholder{
    text-align: center;
}
.searchbar img{
    margin-right: 5px;
}
.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 7px 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 25px;
}
.add{
    background-color: rgb(77, 214, 43);
}
.delete{
    background-color: red;
}
.edit{
    background-color: DodgerBlue;
}
.add:hover{
    background-color: #4dd62b9e;
}
.delete:hover{
    background-color: #ff00009e;
}
.edit:hover{
    background-color: #1e90ff7d;
}
.toolPanel{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #ffffff85;
    z-index: 2;
    display: flex;
    align-items: center;
}
.toolPanel .cc{
    width: fit-content;
    margin: auto;
    padding: 20px 50px;
    border-radius: 20px;
    position: relative;
    background-image: linear-gradient(to right, #a2c5dd, #8fb5d0, #7ca6c4, #6a97b7, #5788ab, #5788ab, #5788ab, #5788ab, #6a97b7, #7ca6c4, #8fb5d0, #a2c5dd);
}
.cc .ccTitle{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: white;
    margin-bottom: 20px;
}
.cc .ccChild{
    display: grid;
    grid-template-columns: 50% 50%;
    margin: 5px;
    font-weight: bold;
}
.ccChild span{
    display: flex;
    align-items: center;
}
.ccChild input{
    padding: 5px;
    font-size: 15px;
}
.x{
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding: 5px 10px;
    text-align: center;
    border-top-right-radius: 20px;
    cursor: pointer;
}
.x:hover{
    background-color: red;
}
.enable{
    cursor: no-drop;
}
.selected{
    background-color: #7777ffa1  !important;
}
.selected:hover{
    background-color: #7777ffa1  !important;
}
#snackbar {
            visibility: hidden; 
            min-width: 250px;
            margin-left: -125px; 
            background-color: #333; 
            color: #fff;
            text-align: center;
            border-radius: 2px; 
            padding: 16px; 
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 30px; 
        }

        /* Show the snackbar when clicking on a button (class added with JavaScript) */
        #snackbar.show {
            visibility: visible; 
            -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
            animation: fadein 0.5s, fadeout 0.5s 2.5s;
        }


    @-webkit-keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 30px; opacity: 1;}
    }

    @keyframes fadein {
    from {top: 0; opacity: 0;}
    to {top: 30px; opacity: 1;}
    }

    @-webkit-keyframes fadeout {
    from {top: 30px; opacity: 1;}
    to {top: 0; opacity: 0;}
    }

    @keyframes fadeout {
    from {top: 30px; opacity: 1;}
    to {top: 0; opacity: 0;}
    }
/* Darker background on mouse-over */

    
</style>