<template>
  <div class="admin">
    <div v-if="load" class="load">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="adminHead">
      <div class="adminTitle">
        <img src="@/assets/logo.png" width="30" alt="" />Bienvenu {{ us.username }} ({{
          us.role
        }})
      </div>
      <div class="logout">
        <span v-on:click="logOutt"
          >Deconnexion<img src="@/assets/icons/logOut.png" width="30" alt=""
        /></span>
      </div>
    </div>
    <div class="adminCnt">
      <div class="sidebar">
        <div class="menu menuTitle">Menu</div>
        <div class="menuScroll">
          <div v-if="us.role != 'prof'" v-on:click="select('c')" class="menu">
            <img src="@/assets/icons/student.png" width="30" alt="" />Clients
          </div>
          <div v-if="us.role != 'prof' && 1 == 2" v-on:click="select('t')" class="menu">
            <img src="@/assets/icons/test.png" width="30" alt="" />Tests
          </div>
          <div v-if="us.role != 'prof'" v-on:click="select('s')" class="menu">
            <img src="@/assets/icons/settings.png" width="30" alt="" />Paramétre de test
          </div>
          <div v-if="us.role != 'assistant'" v-on:click="select('cp')" class="menu">
            <img src="@/assets/icons/comp.png" width="30" alt="" />Compréhension
          </div>
          <div v-if="us.role != 'assistant'" v-on:click="select('q')" class="menu">
            <img src="@/assets/icons/qcm.png" width="30" alt="" />QCM
          </div>
          <div v-if="us.role != 'assistant'" v-on:click="select('a')" class="menu">
            <img src="@/assets/icons/audio.png" width="30" alt="" />Media
          </div>
          <div v-if="us.role != 'prof'" v-on:click="select('mat')" class="menu">
            <img src="@/assets/icons/test.png" width="30" alt="" />Matiéres
          </div>
          <div v-if="us.role != 'prof'" v-on:click="select('vil')" class="menu">
            <img src="@/assets/icons/city.png" width="30" alt="" />Villes
          </div>
          <div
            v-if="us.role != 'prof' && us.role != 'assistant'"
            v-on:click="select('u')"
            class="menu"
          >
            <img src="@/assets/icons/users.png" width="30" alt="" />Users
          </div>
          <div
            v-if="us.role != 'prof' && us.role != 'assistant'"
            v-on:click="select('an')"
            class="menu"
          >
            <img src="@/assets/icons/analyse.png" width="30" alt="" />Analyse Paramétre
          </div>
          <div
            v-if="us.role != 'prof' && us.role != 'assistant'"
            v-on:click="select('ann')"
            class="menu"
          >
            <img src="@/assets/icons/pub.png" width="30" alt="" />Annonces Paramétre
          </div>
        </div>
      </div>
      <div class="tablesCnt">
        <Clients
          v-if="c && us.role != 'prof'"
          :data="clients"
          :user="us.username"
          :total="clients.length"
          :test="tests"
          :matiers="matiers"
          :supp="us.role == 'admin' ? true : false"
        />
        <Tests
          v-if="t"
          :total="tests.length"
          :data="tests"
          :matiers="matiers"
          :supp="us.role == 'admin' ? true : false"
        />
        <Settings v-if="s" :total="settings.length" :data="settings" />
        <Users v-if="u" :total="user.length" :data="user" />
        <Qcm :user="us.username" :matiers="matiers" :total="qcm.length" :users="user" :role="us.role" v-if="q" :data="qcm" />
        <Comp
          :user="us.username"
          :total="comp.length"
          :users="user"
          :matiers="matiers"
          :role="us.role"
          v-if="cp"
          :data="comp"
        />
        <Audio
          :user="us.username"
          :total="audio.length"
          :users="user"
          :matiers="matiers"
          :role="us.role"
          v-if="a"
          :data="audio"
        />
        <Analyse v-if="an" :total="analyse.length" :data="analyse" />
        <Annonce v-if="ann" :total="annonce.length" :data="annonce" />
        <Matiers v-if="mat" :total="matiers.length" :data="matiers" />
        <Villes v-if="vil" :total="villes.length" :data="villes" />
      </div>
    </div>
  </div>
</template>

<script>
window.onbeforeunload = null;
import router from "@/router";
import { Rest } from "../providers/rest";
import Clients from "../components/Clients";
import Tests from "../components/tests";
import Settings from "../components/Settings";
import Users from "../components/Users";
import Matiers from "../components/Matiers";
import Villes from "../components/Villes";
import Qcm from "../components/Aqcm";
import Comp from "../components/Acomp";
import Audio from "../components/Aaudio";
import Analyse from "../components/ASettings";
import Annonce from "../components/Annonce";
export default {
  name: "LoginAdmin",
  data() {
    return {
      login: "",
      load: false,
      clients: [],
      tests: [],
      settings: [],
      comp: [],
      qcm: [],
      audio: [],
      user: [],
      analyse: [],
      matiers: [],
      villes: [],
      us: {},
      c: true,
      t: false,
      s: false,
      cp: false,
      q: false,
      a: false,
      u: false,
      an: false,
      ann: false,
      mat: false,
      vil: false,
    };
  },
  components: {
    Clients,
    Tests,
    Settings,
    Users,
    Qcm,
    Comp,
    Audio,
    Analyse,
    Annonce,
    Matiers,
    Villes,
  },
  methods: {
    logOutt: function () {
      sessionStorage.removeItem("token");
      router.push("/admin");
    },
    loadd() {
      this.load = true;
      new Rest().getAdmin().then((r) => {
        if (r.data.success) {
          this.analyse = r.data.analyse;
          this.annonce = r.data.annonce;
          this.clients = r.data.clients;
          this.villes = r.data.villes;
          this.tests = r.data.tests;
          this.settings = r.data.settings;
          this.comp = r.data.comp;
          this.qcm = r.data.qcm;
          this.audio = r.data.audio;
          this.user = r.data.user;
          this.matiers = r.data.matiers;
          this.load = false;
        } else if (r.data.success == false) {
          this.load = false;
          alert(r.data.msg);
        } else {
          this.load = false;
          alert("Unknown error!");
        }
      });
    },
    select(cmpt) {
      if (cmpt == "c") {
        this.c = true;
        this.mat = false;
        this.t = false;
        this.s = false;
        this.cp = false;
        this.q = false;
        this.a = false;
        this.u = false;
        this.an = false;
        this.ann = false;
        this.vil = false;
      } else if (cmpt == "t") {
        this.c = false;
        this.mat = false;
        this.t = true;
        this.s = false;
        this.cp = false;
        this.q = false;
        this.a = false;
        this.u = false;
        this.an = false;
        this.ann = false;
        this.vil = false;
      } else if (cmpt == "s") {
        this.c = false;
        this.mat = false;
        this.t = false;
        this.s = true;
        this.cp = false;
        this.q = false;
        this.a = false;
        this.u = false;
        this.an = false;
        this.ann = false;
        this.vil = false;
      } else if (cmpt == "cp") {
        this.c = false;
        this.mat = false;
        this.t = false;
        this.s = false;
        this.cp = true;
        this.q = false;
        this.a = false;
        this.u = false;
        this.an = false;
        this.ann = false;
        this.vil = false;
      } else if (cmpt == "q") {
        this.c = false;
        this.mat = false;
        this.t = false;
        this.s = false;
        this.cp = false;
        this.q = true;
        this.a = false;
        this.u = false;
        this.an = false;
        this.ann = false;
        this.vil = false;
      } else if (cmpt == "a") {
        this.c = false;
        this.mat = false;
        this.t = false;
        this.s = false;
        this.cp = false;
        this.q = false;
        this.a = true;
        this.u = false;
        this.an = false;
        this.ann = false;
        this.vil = false;
      } else if (cmpt == "u") {
        this.c = false;
        this.mat = false;
        this.t = false;
        this.s = false;
        this.cp = false;
        this.q = false;
        this.a = false;
        this.u = true;
        this.an = false;
        this.ann = false;
        this.vil = false;
      } else if (cmpt == "an") {
        this.c = false;
        this.mat = false;
        this.t = false;
        this.s = false;
        this.cp = false;
        this.q = false;
        this.a = false;
        this.u = false;
        this.an = true;
        this.ann = false;
        this.vil = false;
      } else if (cmpt == "ann") {
        this.c = false;
        this.mat = false;
        this.t = false;
        this.s = false;
        this.cp = false;
        this.q = false;
        this.a = false;
        this.u = false;
        this.an = false;
        this.ann = true;
        this.vil = false;
      }
      else if (cmpt == "mat") {
        this.c = false;
        this.mat = true;
        this.t = false;
        this.s = false;
        this.cp = false;
        this.q = false;
        this.a = false;
        this.u = false;
        this.an = false;
        this.ann = false;
        this.vil = false;
      }
      else if (cmpt == "vil") {
        this.c = false;
        this.mat = false;
        this.t = false;
        this.s = false;
        this.cp = false;
        this.q = false;
        this.a = false;
        this.u = false;
        this.an = false;
        this.ann = false;
        this.vil = true;
      }
    },
  },
  beforeMount: function () {
    if (sessionStorage.getItem("token") == null) {
      router.push("/admin");
    } else {
      this.login = sessionStorage.getItem("token");
      new Rest()
        .getUser(this.login)
        .then((r) => {
          if (r.data.success == true) {
            this.us = r.data.data;
            if (this.us.role == "prof") this.cp = true;
          } else {
            alert(r.data.msg);
            router.push("/admin");
          }
        })
        .catch((e) => {
          alert(e);
          router.push("/admin");
        });
      this.loadd();
    }
  },
};
</script>

<style>
#customers {
  font-size: 16px;
}
#customers td,
#customers th {
  padding: 3px !important;
}
@media screen and (max-width: 1200px) {
  .admin .adminCnt {
    width: 100%;
    display: block !important;
  }
  .admin .sidebar {
    display: flex !important;
    width: 100% !important;
    height: fit-content !important;
  }
  .admin .menuScroll {
    display: flex !important;
    height: fit-content !important;
  }
  .admin .tablesCnt {
    width: 100% !important;
  }
  .admin .menu {
    border: none !important;
    border-right: 1px solid black !important;
  }
  .searchbar input,
  select {
    border-radius: 20px;
    padding: 2px;
    font-size: 13px;
  }
}
#nav {
  display: none;
}
.admin .adminHead {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: black;
  background-color: #d2d2d2;
  border-bottom: 1px solid #e9e9eb;
}
.admin .adminTitle {
  padding: 20px;
  font-weight: bold;
  width: 50%;
  display: flex;
  align-items: center;
}
.admin .adminTitle img {
  margin-right: 10px;
}
.admin .logout {
  width: 40%;
}
.admin .logout span {
  padding: 22px 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
}
.admin .logout span img {
  margin-left: 5px;
}
.admin .logout span:hover {
  background-color: #ffffff9c;
}
.admin .adminCnt {
  width: 100%;
  height: calc(100vh - 74px);
  display: flex;
}
.admin .sidebar {
  width: 15%;
  height: 100%;
  background-color: #d2d2d2;
  border-right: 1px solid #e9e9eb;
}
.admin .menu {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
}
.admin .menu img {
  margin-right: 5px;
}
.admin .menu span:nth-child(1) {
  text-align: end;
}
.admin .menuTitle {
  font-weight: bold;
  color: white;
  font-size: 20px;
  cursor: auto;
  border-color: white;
  text-align: center;
  display: block;
}
.admin .menuScroll {
  overflow-y: auto;
  height: calc(100% - 44px);
}
.admin .menuScroll .menu:hover {
  font-weight: bold;
  border-width: 3px;
}
.admin .tablesCnt {
  width: 85%;
}
.admin .load {
  position: absolute;
  height: 100vh;
  z-index: 4;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #ffffff6e;
  top: 0;
}
.admin .lds-ring {
  margin: auto;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.admin .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid red;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: red transparent transparent transparent;
}
.admin .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.admin .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.admin .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 7px 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 20px;
}
.add {
  background-color: rgb(77, 214, 43);
}
.delete {
  background-color: red;
}
.edit {
  background-color: DodgerBlue;
}
.add:hover {
  background-color: #4dd62b9e;
}
.delete:hover {
  background-color: #ff00009e;
}
.edit:hover {
  background-color: #1e90ff7d;
}
</style>
