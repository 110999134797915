<template>
  <div class="users">
    <div id="snackbar"></div>
    <div v-if="panelAdd" class="toolPanel">
      <div class="cc">
        <div class="x" v-on:click="hideAdd">X</div>
        <div class="ccTitle">Ajouter Ville</div>
        <div class="ccChild">
          <span>Ville Nom:</span><input id="addVille" type="text" />
        </div>
        <div v-if="addError" style="color: yellow; text-align: center">
          Ville Nom obligatoire!
        </div>
        <div style="text-align: center">
          <button v-on:click="add" class="btn add">
            <i class="fa fa-plus-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelSupp" class="toolPanel">
      <div class="cc">
        <div class="ccTitle" style="color: red">
          Do you really want to delete ('{{ selected.nom }}')?
        </div>
        <div style="text-align: center">
          <button v-on:click="delet" class="btn delete">
            <i class="fa fa-trash"></i>
          </button>
          <button v-on:click="hideSupp" class="btn edit">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelEdit" class="toolPanel">
      <div class="cc">
        <div class="x" v-on:click="hideEdit">X</div>
        <div class="ccTitle">Edit Ville</div>
        <div class="ccChild">
          <span>Ville Nom:</span
          ><input id="editVille" type="text" :value="selected.nom" />
        </div>
        <div v-if="editError" style="color: yellow; text-align: center">
          Ville Nom obligatoire!
        </div>
        <div style="text-align: center">
          <button v-on:click="edit" class="btn edit"><i class="fa fa-edit"></i></button>
        </div>
      </div>
    </div>
    <div class="TableTitle">
      <img src="@/assets/icons/test.png" width="50" alt="" /> Matiéres (
      <span id="Totcurr">{{ total }}</span> {{ "/" + total }})
    </div>
    <div class="toolbox">
      <div style="margin: auto; text-align: center">
        <button v-on:click="showAdd" class="btn add">
          <i class="fa fa-plus-square"></i>
        </button>
        <button
          v-on:click="showSupp"
          :disabled="selected.idVille ? false : true"
          :class="!selected.idVille ? 'btn delete enable' : 'btn delete'"
        >
          <i class="fa fa-trash"></i>
        </button>
        <button
          v-on:click="showEdit"
          :disabled="selected.idVille ? false : true"
          :class="!selected.idVille ? 'btn edit enable' : 'btn edit'"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
      <div class="searchbar">
        <img src="@/assets/icons/search.png" width="25" alt="" />
        <input type="text" id="search" v-on:keyup="searchh" placeholder="search" />
      </div>
    </div>
    <div class="customersCnt">
      <table id="customers">
        <thead>
          <tr style="cursor: initial">
            <th>Id Ville</th>
            <th>Ville Nom</th>
            <th style="padding: 0; width: 15px; min-width: 0px"></th>
          </tr>
        </thead>
        <div class="tablesScroll">
          <tr v-for="d in data" :key="d.idVille" v-on:click="select(d)" :id="d.idVille">
            <td>{{ d.idVille ? d.idVille : "null" }}</td>
            <td>{{ d.nom ? d.nom : "null" }}</td>
          </tr>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import { Rest } from "@/providers/rest";

export default {
  name: "Clients",
  data() {
    return {
      panelAdd: false,
      panelEdit: false,
      selected: {},
      editError: false,
      addError: false,
      panelSupp: false,
    };
  },
  props: ["data", "total"],
  methods: {
    hideAdd: function () {
      this.panelAdd = false;
    },
    showAdd: function () {
      this.panelAdd = true;
    },
    hideEdit: function () {
      this.panelEdit = false;
    },
    showEdit: function () {
      this.panelEdit = true;
    },
    hideSupp: function () {
      this.panelSupp = false;
    },
    showSupp: function () {
      this.panelSupp = true;
    },
    delet() {
      this.$parent.load = true;
        const data = {
          type: "ville",
          id: this.selected.idVille,
        };
        new Rest()
          .deleteAdmin(data)
          .then((r) => {
            this.hideSupp();
            if (r.data.success) {
              this.snackBar(r.data.msg);
              this.select(this.selected);
              this.$parent.load = false;
              this.$parent.loadd();
            } else {
              alert(r.data.msg);
              this.$parent.load = false;
            }
          })
          .catch((e) => {
            alert(e);
            this.$parent.load = false;
          });
      
    },
    select(d) {
      if (this.selected.idVille == d.idVille) {
        this.selected = {};
        document.getElementById(d.idVille).className = "";
      } else {
        this.selected = d;
        for (const selected of document.getElementsByClassName("selected")) {
          selected.className = "";
        }
        document.getElementById(d.idVille).className = "selected";
      }
    },
    edit() {
      this.$parent.load = true;
      const nom = document.getElementById("editVille").value;
      if (!nom) {
        this.editError = true;
        this.$parent.load = false;
      } else {
        const data = {
          type: "ville",
          nom: nom,
          id: this.selected.idVille,
        };
        new Rest()
          .updateAdmin(data)
          .then((r) => {
            this.hideEdit();
            if (r.data.success) {
              this.snackBar(r.data.msg);
              this.select(this.selected);
              this.$parent.load = false;
              this.$parent.loadd();
            } else {
              alert(r.data.msg);
              this.$parent.load = false;
            }
          })
          .catch((e) => {
            alert(e);
            this.$parent.load = false;
          });
      }
    },
    snackBar: function (msg) {
      const x = document.getElementById("snackbar");
      if (x) {
        x.className = "show";
        x.innerText = msg;
        setTimeout(function () {
          if (x) {
            x.className = x.className.replace("show", "");
          }
        }, 5000);
      }
    },
    add() {
      this.$parent.load = true;
      const nom = document.getElementById("addVille").value;
      if (!nom) {
        this.addError = true;
        this.$parent.load = false;
      } else {
        const data = {
          type: "ville",
          nom: nom,
        };
        new Rest()
          .addAdmin(data)
          .then((r) => {
            this.hideAdd();
            if (r.data.success) {
              this.snackBar(r.data.msg);
              this.$parent.load = false;
              this.$parent.loadd();
            } else {
              alert(r.data.msg);
              this.$parent.load = false;
            }
          })
          .catch((e) => {
            alert(e);
            this.$parent.load = false;
          });
      }
    },
    searchh() {
      let curr = 0;
      const txt = document.getElementById("search").value.toLowerCase().trim();
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        if (t.innerText.toLowerCase().indexOf(txt) == -1) {
          t.style.display = "none";
        } else {
          t.style.display = "inline-table";
          curr++;
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
    },
  },
};
</script>

<style>
.TableTitle {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: auto;
  padding-top: 20px;
  font-size: 30px;
  font-weight: bold;
}
.TableTitle img {
  margin-right: 10px;
}
.users {
  position: relative;
  height: 100%;
}
.customersCnt {
  height: calc(100% - 133px);
  max-height: calc(100% - 133px);
}
.tablesScroll {
  overflow-y: scroll;
  height: calc(100vh - 268px);
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 99.9%;
  margin: auto;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 30px;
  overflow-wrap: break-word;
  min-width: 30px;
}
tr {
  display: inline-table;
  width: 99.9%;
  cursor: pointer;
}
thead tr {
  display: inline-table !important;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #bb1013;
  color: white;
}
.toolbox {
  padding: 10px;
  background-color: #909090;
  display: flex;
}
.searchbar {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.searchbar input {
  border-radius: 20px;
  padding: 5px;
  font-size: 15px;
}
.searchbar input::placeholder {
  text-align: center;
}
.searchbar img {
  margin-right: 5px;
}
.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 7px 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 25px;
}
.add {
  background-color: rgb(77, 214, 43);
}
.delete {
  background-color: red;
}
.edit {
  background-color: DodgerBlue;
}
.add:hover {
  background-color: #4dd62b9e;
}
.delete:hover {
  background-color: #ff00009e;
}
.edit:hover {
  background-color: #1e90ff7d;
}
.toolPanel {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff85;
  z-index: 2;
  display: flex;
  align-items: center;
}
.toolPanel .cc {
  width: fit-content;
  margin: auto;
  padding: 20px 50px;
  border-radius: 20px;
  position: relative;
  background-image: linear-gradient(
    to right,
    #a2c5dd,
    #8fb5d0,
    #7ca6c4,
    #6a97b7,
    #5788ab,
    #5788ab,
    #5788ab,
    #5788ab,
    #6a97b7,
    #7ca6c4,
    #8fb5d0,
    #a2c5dd
  );
}
.cc .ccTitle {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}
.cc .ccChild {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 5px;
  font-weight: bold;
}
.ccChild span {
  display: flex;
  align-items: center;
}
.ccChild input {
  padding: 5px;
  font-size: 15px;
}
.x {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  border-top-right-radius: 20px;
  cursor: pointer;
}
.x:hover {
  background-color: red;
}
.enable {
  cursor: no-drop;
}
.selected {
  background-color: #7777ffa1 !important;
}
.selected:hover {
  background-color: #7777ffa1 !important;
}
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
/* Darker background on mouse-over */
</style>
