<template>
  <div class="Acomp">
    <div id="snackbar"></div>
    <div v-if="panelShow" class="toolPanel" style="z-index: 3">
      <div class="cc" style="">
        <div class="x img" style="color: red" v-on:click="hideShow">X</div>
        <div class="ccTitle">Compréhension id = '{{ selected.main.idQComp }}'</div>
        <div class="ccChild" style="grid-template-columns: 20% 80%">
          <span style="color: red">Prof:</span
          >{{ selected.main.prof ? selected.main.prof : "null" }}
        </div>
        <div v-if="selected.main.CheminImage" class="ccTitle">
          <img
            style="width: 50%"
            :src="
              'https://api.victory-partners.ma/victory%20api/upload/' +
              selected.main.CheminImage
            "
            alt=""
          />
        </div>
        <div class="ccChild" style="grid-template-columns: 20% 80%">
          <span style="color: red">Titre:</span
          >{{ selected.main.LibelleQComp ? selected.main.LibelleQComp : "null" }}
        </div>
        <div class="ccChild" style="grid-template-columns: 20% 80%">
          <span style="color: red">Libellé:</span
          >{{ selected.main.TitreTexteQComp ? selected.main.TitreTexteQComp : "null" }}
        </div>
        <div v-if="selected.main.audio" style="margin-bottom: 0" class="ccTitle">
          <audio @loadstart="console.log('gg')" controls style="width: 70%">
            <source
              :src="
                'https://api.victory-partners.ma/victory%20api/upload/' +
                selected.main.audio
              "
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
        </div>
        <div class="ccChild" style="display: inline">
          <span style="color: red">Text:</span>
          <div style="border: 1px dashed; padding: 10px; border-radius: 20px">
            {{ selected.main.TextQComp ? selected.main.TextQComp : "null" }}
          </div>
        </div>
        <div class="ccChild" style="grid-template-columns: 20% 80%">
          <span style="color: red">Matiére:</span
          >{{ selected.main.LibelleMatiere ? selected.main.LibelleMatiere : "null" }}
        </div>
        <div class="ccChild" style="grid-template-columns: 20% 80%">
          <span style="color: red">Niveau:</span
          >{{ selected.main.LibelleNiveau ? selected.main.LibelleNiveau : "null" }}
        </div>
        <div class="ccTitle" style="margin: 20px">
          Compréhension id = '{{ selected.main.idQComp }}' Sousquestions
        </div>
        <table id="customers" class="sousQcus" style="background-color: white">
          <thead>
            <tr style="cursor: initial">
              <th class="hiddenCol">Id Sous Question</th>
              <th>Ennoncé</th>
              <th>Choix 1</th>
              <th>Choix 2</th>
              <th>Choix 3</th>
              <th>Choix 4</th>
              <th>Bonne Réponse</th>
            </tr>
          </thead>
          <div>
            <tr v-for="d in selected.sousQues" :key="d.idSousQComp">
              <td class="hiddenCol">{{ d.idSousQComp ? d.idSousQComp : "null" }}</td>
              <td>{{ d.EnonceQuestion ? d.EnonceQuestion : "null" }}</td>
              <td>{{ d.Choix1 ? d.Choix1 : "null" }}</td>
              <td>{{ d.Choix2 ? d.Choix2 : "null" }}</td>
              <td>{{ d.Choix3 ? d.Choix3 : "null" }}</td>
              <td>{{ d.Choix4 ? d.Choix4 : "null" }}</td>
              <td>{{ d.BonneReponse ? d.BonneReponse : "null" }}</td>
            </tr>
          </div>
        </table>
      </div>
    </div>
    <div v-if="panelAdd" class="toolPanel">
      <div class="cc">
        <div class="x" v-on:click="hideAdd">X</div>
        <div class="ccTitle">Add Compréhension</div>
        <div class="ccChild" style="display: inline">
          <span>Libelle:</span><textarea id="addLib"></textarea>
        </div>
        <div class="ccChild"><span>Titre:</span><input type="text" id="addTitle" /></div>
        <div class="ccChild" style="display: inline">
          <span>text:</span><textarea rows="5" id="addText"></textarea>
        </div>
        <div class="ccChild">
          <span>Matiére:</span>
          <select id="addMat">
            <option
              v-for="m of matiers"
              :key="m.idMatiere"
              :value="m.idMatiere"
            >
              {{m.LibelleMatiere}}
            </option>
          </select>
        </div>
        <div class="ccChild" style="grid-template-columns: 30% 70%">
          <span>Niveau:</span>
          <span
            style="display: grid; grid-template-columns: 50% 50%; font-weight: normal"
          >
            <span v-for="n in niveaux" :key="n.idNiveau" style="padding: 5px">
              <input type="checkbox" @click="selectLevel(n.idNiveau)" />
              {{ n.LibelleNiveau }}
            </span>
          </span>
        </div>
        <div class="ccChild">
          <span>Import Audio (Pas Obligatoire):</span>
          <div class="import" @click="Afile.name != null ? (Afile = {}) : null">
            <span>
              <i
                v-if="Afile.name == null"
                style="font-size: 25px"
                class="fa fa-upload"
              ></i>
              <i
                v-if="Afile.name != null"
                style="font-size: 25px; color: red"
                class="fa fa-close"
              ></i>
              <div>{{ Afile.name != null ? Afile.name : "No File chosen" }}</div>
            </span>
            <input
              @change="chooseAudio"
              type="file"
              :style="Afile.name != null ? 'width:0' : ''"
              id="addAudio"
              accept="audio/mp3,audio/mp4"
            />
          </div>
        </div>
        <div class="ccChild">
          <span>Import Image (Pas Obligatoire):</span>
          <div class="import" @click="file.name != null ? (file = {}) : null">
            <span>
              <i
                v-if="file.name == null"
                style="font-size: 25px"
                class="fa fa-upload"
              ></i>
              <i
                v-if="file.name != null"
                style="font-size: 25px; color: red"
                class="fa fa-close"
              ></i>
              <div>{{ file.name != null ? file.name : "No File chosen" }}</div>
            </span>
            <input
              @change="chooseImg"
              type="file"
              :style="file.name != null ? 'width:0' : ''"
              :disabled="selectedImg || file.name != null ? true : false"
              :class="selectedImg ? 'enabled' : ''"
              id="addImage"
              accept="image/png, image/jpeg"
            />
          </div>
        </div>
        <div
          class="ccTitle"
          style="text-align: left; color: red; margin-top: 25px; margin-bottom: 0"
        >
          Sous Question 1:
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span><textarea rows="3" id="1addEno"></textarea>
        </div>
        <div class="ccChild"><span>Choix 1:</span><input id="1addC1" type="text" /></div>
        <div class="ccChild"><span>Choix 2:</span><input id="1addC2" type="text" /></div>
        <div class="ccChild"><span>Choix 3:</span><input id="1addC3" type="text" /></div>
        <div class="ccChild"><span>Choix 4:</span><input id="1addC4" type="text" /></div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="1addBon">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>
        <div
          class="ccTitle"
          style="text-align: left; color: red; margin-top: 25px; margin-bottom: 0"
        >
          Sous Question 2:
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span><textarea rows="3" id="2addEno"></textarea>
        </div>
        <div class="ccChild"><span>Choix 1:</span><input id="2addC1" type="text" /></div>
        <div class="ccChild"><span>Choix 2:</span><input id="2addC2" type="text" /></div>
        <div class="ccChild"><span>Choix 3:</span><input id="2addC3" type="text" /></div>
        <div class="ccChild"><span>Choix 4:</span><input id="2addC4" type="text" /></div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="2addBon">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>
        <div
          class="ccTitle"
          style="text-align: left; color: red; margin-top: 25px; margin-bottom: 0"
        >
          Sous Question 3:
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span><textarea rows="3" id="3addEno"></textarea>
        </div>
        <div class="ccChild"><span>Choix 1:</span><input id="3addC1" type="text" /></div>
        <div class="ccChild"><span>Choix 2:</span><input id="3addC2" type="text" /></div>
        <div class="ccChild"><span>Choix 3:</span><input id="3addC3" type="text" /></div>
        <div class="ccChild"><span>Choix 4:</span><input id="3addC4" type="text" /></div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="3addBon">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>
        <div
          class="ccTitle"
          style="text-align: left; color: red; margin-top: 25px; margin-bottom: 0"
        >
          Sous Question 4:
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span><textarea rows="3" id="4addEno"></textarea>
        </div>
        <div class="ccChild"><span>Choix 1:</span><input id="4addC1" type="text" /></div>
        <div class="ccChild"><span>Choix 2:</span><input id="4addC2" type="text" /></div>
        <div class="ccChild"><span>Choix 3:</span><input id="4addC3" type="text" /></div>
        <div class="ccChild"><span>Choix 4:</span><input id="4addC4" type="text" /></div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="4addBon">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>
        <div v-if="addError" style="color: yellow; text-align: center">
          Some inputs required!
        </div>
        <div style="text-align: center">
          <button v-on:click="add" class="btn add">
            <i class="fa fa-plus-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelEdit" class="toolPanel">
      <div class="cc">
        <div class="x" v-on:click="hideEdit">X</div>
        <div class="ccTitle">Edit Compréhension</div>
        <div class="ccChild" style="display: inline">
          <span>Libelle:</span
          ><textarea id="editLib" :value="selected.main.LibelleQComp"></textarea>
        </div>
        <div class="ccChild">
          <span>Titre:</span
          ><input type="text" id="editTitle" :value="selected.main.TitreTexteQComp" />
        </div>
        <div class="ccChild" style="display: inline">
          <span>text:</span
          ><textarea rows="5" id="editText" :value="selected.main.TextQComp"></textarea>
        </div>
        <div class="ccChild">
          <span>Matiére:</span>
          <select id="editMat">
            <option
              v-for="m of matiers"
              :key="m.idMatiere"
              :value="m.idMatiere"
              :selected="selected.Matiere_idMatiere == m.idMatiere ? true : false"
            >
              {{m.LibelleMatiere}}
            </option>
          </select>
        </div>
        <div class="ccChild">
          <span>Niveau:</span>
          <select id="editNv">
            <option
              v-for="n in niveaux"
              :key="n.idNiveau"
              :selected="selected.main.Niveau_idNiveau == n.idNiveau ? true : false"
              :value="n.idNiveau"
            >
              {{ n.LibelleNiveau }}
            </option>
          </select>
        </div>
        <div>Edit image? : <input v-model="editImage" type="checkbox" /></div>
        <div v-if="editImage">
          <div class="ccChild">
            <span>Import Image (Pas Obligatoire):</span>
            <div class="import" @click="file.name != null ? (file = {}) : null">
              <span>
                <i
                  v-if="file.name == null"
                  style="font-size: 25px"
                  class="fa fa-upload"
                ></i>
                <i
                  v-if="file.name != null"
                  style="font-size: 25px; color: red"
                  class="fa fa-close"
                ></i>
                <div>{{ file.name != null ? file.name : "No File chosen" }}</div>
              </span>
              <input
                @change="chooseImg"
                type="file"
                :style="file.name != null ? 'width:0' : ''"
                :disabled="selectedImg || file.name != null ? true : false"
                :class="selectedImg ? 'enabled' : ''"
                id="addImage"
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
        </div>
        <div>Edit audio? : <input v-model="editAudio" type="checkbox" /></div>
        <div v-if="editAudio">
          <div class="ccChild">
            <span>Import Audio (Pas Obligatoire):</span>
            <div class="import" @click="Afile.name != null ? (Afile = {}) : null">
              <span>
                <i
                  v-if="Afile.name == null"
                  style="font-size: 25px"
                  class="fa fa-upload"
                ></i>
                <i
                  v-if="Afile.name != null"
                  style="font-size: 25px; color: red"
                  class="fa fa-close"
                ></i>
                <div>{{ Afile.name != null ? Afile.name : "No File chosen" }}</div>
              </span>
              <input
                @change="chooseAudio"
                type="file"
                :style="Afile.name != null ? 'width:0' : ''"
                id="addAudio"
                accept="audio/mp3,audio/mp4"
              />
            </div>
          </div>
        </div>
        <div
          class="ccTitle"
          style="text-align: left; color: red; margin-top: 25px; margin-bottom: 0"
        >
          Sous Question 1:
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span
          ><textarea
            rows="3"
            id="1editEno"
            :value="selected.sousQues[0].EnonceQuestion"
          ></textarea>
        </div>
        <div class="ccChild">
          <span>Choix 1:</span
          ><input id="1editC1" type="text" :value="selected.sousQues[0].Choix1" />
        </div>
        <div class="ccChild">
          <span>Choix 2:</span
          ><input id="1editC2" type="text" :value="selected.sousQues[0].Choix2" />
        </div>
        <div class="ccChild">
          <span>Choix 3:</span
          ><input id="1editC3" type="text" :value="selected.sousQues[0].Choix3" />
        </div>
        <div class="ccChild">
          <span>Choix 4:</span
          ><input id="1editC4" type="text" :value="selected.sousQues[0].Choix4" />
        </div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="1editBon">
            <option
              value="A"
              :selected="selected.sousQues[0].BonneReponse == 'A' ? true : false"
            >
              A
            </option>
            <option
              value="B"
              :selected="selected.sousQues[0].BonneReponse == 'B' ? true : false"
            >
              B
            </option>
            <option
              value="C"
              :selected="selected.sousQues[0].BonneReponse == 'C' ? true : false"
            >
              C
            </option>
            <option
              value="D"
              :selected="selected.sousQues[0].BonneReponse == 'D' ? true : false"
            >
              D
            </option>
          </select>
        </div>
        <div
          class="ccTitle"
          style="text-align: left; color: red; margin-top: 25px; margin-bottom: 0"
        >
          Sous Question 2:
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span
          ><textarea
            rows="3"
            id="2editEno"
            :value="selected.sousQues[1].EnonceQuestion"
          ></textarea>
        </div>
        <div class="ccChild">
          <span>Choix 1:</span
          ><input id="2editC1" type="text" :value="selected.sousQues[1].Choix1" />
        </div>
        <div class="ccChild">
          <span>Choix 2:</span
          ><input id="2editC2" type="text" :value="selected.sousQues[1].Choix2" />
        </div>
        <div class="ccChild">
          <span>Choix 3:</span
          ><input id="2editC3" type="text" :value="selected.sousQues[1].Choix3" />
        </div>
        <div class="ccChild">
          <span>Choix 4:</span
          ><input id="2editC4" type="text" :value="selected.sousQues[1].Choix4" />
        </div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="2editBon">
            <option
              value="A"
              :selected="selected.sousQues[1].BonneReponse == 'A' ? true : false"
            >
              A
            </option>
            <option
              value="B"
              :selected="selected.sousQues[1].BonneReponse == 'B' ? true : false"
            >
              B
            </option>
            <option
              value="C"
              :selected="selected.sousQues[1].BonneReponse == 'C' ? true : false"
            >
              C
            </option>
            <option
              value="D"
              :selected="selected.sousQues[1].BonneReponse == 'D' ? true : false"
            >
              D
            </option>
          </select>
        </div>
        <div
          class="ccTitle"
          style="text-align: left; color: red; margin-top: 25px; margin-bottom: 0"
        >
          Sous Question 3:
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span
          ><textarea
            rows="3"
            id="3editEno"
            :value="selected.sousQues[2].EnonceQuestion"
          ></textarea>
        </div>
        <div class="ccChild">
          <span>Choix 1:</span
          ><input id="3editC1" type="text" :value="selected.sousQues[2].Choix1" />
        </div>
        <div class="ccChild">
          <span>Choix 2:</span
          ><input id="3editC2" type="text" :value="selected.sousQues[2].Choix2" />
        </div>
        <div class="ccChild">
          <span>Choix 3:</span
          ><input id="3editC3" type="text" :value="selected.sousQues[2].Choix3" />
        </div>
        <div class="ccChild">
          <span>Choix 4:</span
          ><input id="3editC4" type="text" :value="selected.sousQues[2].Choix4" />
        </div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="3editBon">
            <option
              value="A"
              :selected="selected.sousQues[2].BonneReponse == 'A' ? true : false"
            >
              A
            </option>
            <option
              value="B"
              :selected="selected.sousQues[2].BonneReponse == 'B' ? true : false"
            >
              B
            </option>
            <option
              value="C"
              :selected="selected.sousQues[2].BonneReponse == 'C' ? true : false"
            >
              C
            </option>
            <option
              value="D"
              :selected="selected.sousQues[2].BonneReponse == 'D' ? true : false"
            >
              D
            </option>
          </select>
        </div>
        <div
          class="ccTitle"
          style="text-align: left; color: red; margin-top: 25px; margin-bottom: 0"
        >
          Sous Question 4:
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span
          ><textarea
            rows="3"
            id="4editEno"
            :value="selected.sousQues[3].EnonceQuestion"
          ></textarea>
        </div>
        <div class="ccChild">
          <span>Choix 1:</span
          ><input id="4editC1" type="text" :value="selected.sousQues[3].Choix1" />
        </div>
        <div class="ccChild">
          <span>Choix 2:</span
          ><input id="4editC2" type="text" :value="selected.sousQues[3].Choix2" />
        </div>
        <div class="ccChild">
          <span>Choix 3:</span
          ><input id="4editC3" type="text" :value="selected.sousQues[3].Choix3" />
        </div>
        <div class="ccChild">
          <span>Choix 4:</span
          ><input id="4editC4" type="text" :value="selected.sousQues[3].Choix4" />
        </div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="4editBon">
            <option
              value="A"
              :selected="selected.sousQues[3].BonneReponse == 'A' ? true : false"
            >
              A
            </option>
            <option
              value="B"
              :selected="selected.sousQues[3].BonneReponse == 'B' ? true : false"
            >
              B
            </option>
            <option
              value="C"
              :selected="selected.sousQues[3].BonneReponse == 'C' ? true : false"
            >
              C
            </option>
            <option
              value="D"
              :selected="selected.sousQues[3].BonneReponse == 'D' ? true : false"
            >
              D
            </option>
          </select>
        </div>
        <div v-if="editError" style="color: yellow; text-align: center">
          Some inputs required!
        </div>
        <div style="text-align: center">
          <button v-on:click="edit" class="btn edit"><i class="fa fa-edit"></i></button>
          <button v-if="selected.main.valide == 0 && role == 'admin'" v-on:click="confirmer" class="btn add">confirmer</button>
        </div>
      </div>
    </div>
    <div v-if="panelSupp" class="toolPanel">
      <div class="cc">
        <div class="ccTitle" style="color: red">
          Do you really want to delete Compréhension(id:'{{ selected.main.idQComp }}')?
        </div>
        <div style="text-align: center">
          <button v-on:click="delet" class="btn delete">
            <i class="fa fa-trash"></i>
          </button>
          <button v-on:click="hideSupp" class="btn edit">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="TableTitle">
      <img src="@/assets/icons/comp.png" width="50" alt="" /> Compréhension (
      <span id="Totcurr">{{ total }}</span> {{ "/" + total }})
    </div>
    <div class="toolbox">
      <div style="margin: auto; text-align: center">
        <button v-on:click="showAdd" class="btn add">
          <i class="fa fa-plus-square"></i>
        </button>
        <button
          v-on:click="showSupp"
          :disabled="selected.main ? false : true"
          :class="!selected.main ? 'btn delete enable' : 'btn delete'"
        >
          <i class="fa fa-trash"></i>
        </button>
        <button
          v-on:click="showShow"
          :disabled="selected.main ? false : true"
          :class="!selected.main ? 'btn edit enable' : 'btn edit'"
        >
          <i class="fa fa-eye"></i>
        </button>
        <button
          v-on:click="showEdit"
          :disabled="selected.main ? false : true"
          :class="!selected.main ? 'btn edit enable' : 'btn edit'"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
      <div class="searchbar">
        <select id="Psearch" @change="filter">
          <option value="P">Filter by Prof</option>
          <option v-for="u in users" :key="u.username" :value="'P' + u.username">
            {{ u.username }}
          </option>
        </select>
        <select id="Msearch" @change="filter">
          <option value="M">Filter by Matiére</option>
          <option
              v-for="m of matiers"
              :key="'M'+m.idMatiere"
              :value="'M'+m.idMatiere"
            >
              {{m.LibelleMatiere}}
            </option>
        </select>
        <select id="Nsearch" @change="filter">
          <option value="N">Filter by Niveau</option>
          <option v-for="n in niveaux" :key="n.idNiveau" :value="'N' + n.idNiveau">
            {{ n.LibelleNiveau }}
          </option>
        </select>
        <img src="@/assets/icons/search.png" width="25" alt="" />
        <input type="text" id="search" @keyup="searchh" placeholder="search" />
      </div>
    </div>
    <div class="customersCnt">
      <table id="customers">
        <thead>
          <tr style="cursor: initial">
            <th class="hiddenCol">Id Compréhension</th>
            <th>Libelle</th>
            <th class="hiddenCol">Titre</th>
            <th>Text</th>
            <th>Matiére</th>
            <th>Niveau</th>
            <th>Prof</th>
            <th class="hiddenCol" style="padding: 0; width: 15px; min-width: 0px"></th>
          </tr>
        </thead>
        <div class="tablesScroll">
          <tr
            v-for="d in data"
            :key="d.main.idQComp"
            v-on:click="select(d)"
            :id="d.main.idQComp"
            :style="d.main.valide==0?'background-color:#f3f390':''"
          >
            <td class="hiddenCol">{{ d.main.idQComp ? d.main.idQComp : "null" }}</td>
            <td>
              {{ d.main.LibelleQComp ? d.main.LibelleQComp.substr(0, 50) : "null" }}
            </td>
            <td class="hiddenCol">
              {{ d.main.TitreTexteQComp ? d.main.TitreTexteQComp : "null" }}
            </td>
            <td>
              {{ d.main.TextQComp ? d.main.TextQComp.substr(0, 50) + "..." : "null" }}
            </td>
            <td :class="'M' + d.main.Matiere_idMatiere">
              {{ d.main.LibelleMatiere ? d.main.LibelleMatiere : "null" }}
            </td>
            <td :class="'N' + d.main.Niveau_idNiveau">
              {{ d.main.LibelleNiveau ? d.main.LibelleNiveau : "null" }}
            </td>
            <td :class="'P' + d.main.prof">
              {{ d.main.prof ? d.main.prof : "null" }}
            </td>
          </tr>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import { Rest } from "@/providers/rest";
import axios from "axios";

export default {
  name: "Comp",
  data() {
    return {
      panelShow: false,
      panelAdd: false,
      panelEdit: false,
      selected: {},
      editError: false,
      addError: false,
      panelSupp: false,
      niveaux: [],
      level: [],
      file: {},
      Afile: {},
      editImage: false,
      editAudio: false,
    };
  },
  props: ["data", "user", "users", "total","role",'matiers'],
  methods: {
    confirmer(){
      this.$parent.load = true;
      new Rest()
        .confirmerQuestion('comp',this.selected.main.idQComp)
        .then((r) => {
          this.hideEdit();
          if (r.data.success) {
            this.snackBar(r.data.msg);
            this.select(this.selected);
            this.$parent.load = false;
            this.$parent.loadd();
          } else {
            alert(r.data.msg);
            this.$parent.load = false;
          }
        })
        .catch((e) => {
          alert(e);
          this.$parent.load = false;
        });
    },
    selectLevel: function (id) {
      if (this.level.indexOf(id) != -1) {
        this.level.splice(this.level.indexOf(id), 1);
      } else {
        this.level.push(id);
      }
    },
    hideAdd: function () {
      this.panelAdd = false;
      this.file = {};
      this.Afile = {};
      this.level = [];
    },
    showAdd: function () {
      this.panelAdd = true;
    },
    hideEdit: function () {
      this.panelEdit = false;
      this.file = {};
      this.Afile = {};
      this.level = [];
    },
    showEdit: function () {
      this.panelEdit = true;
    },
    hideShow: function () {
      this.panelShow = false;
    },
    showShow: function () {
      this.panelShow = true;
    },
    hideSupp: function () {
      this.panelSupp = false;
    },
    showSupp: function () {
      this.panelSupp = true;
    },
    delet() {
      this.$parent.load = true;
      const data = {
        type: "comp",
        id: this.selected.main.idQComp,
      };
      new Rest()
        .deleteAdmin(data)
        .then((r) => {
          this.hideSupp();
          if (r.data.success) {
            this.snackBar(r.data.msg);
            this.select(this.selected);
            this.$parent.load = false;
            this.$parent.loadd();
          } else {
            alert(r.data.msg);
            this.$parent.load = false;
          }
        })
        .catch((e) => {
          alert(e);
          this.$parent.load = false;
        });
    },
    select(d) {
      if (this.selected.main != null && this.selected.main.idQComp == d.main.idQComp) {
        this.selected = {};
        document.getElementById(d.main.idQComp).className = "";
      } else {
        this.selected = d;
        for (const selected of document.getElementsByClassName("selected")) {
          selected.className = "";
        }
        document.getElementById(d.main.idQComp).className = "selected";
      }
    },
    snackBar: function (msg) {
      const x = document.getElementById("snackbar");
      if (x) {
        x.className = "show";
        x.innerText = msg;
        setTimeout(function () {
          if (x) {
            x.className = x.className.replace("show", "");
          }
        }, 5000);
      }
    },
    add() {
      this.$parent.load = true;
      const libelle = document.getElementById("addLib").value;
      const title = document.getElementById("addTitle").value;
      const text = document.getElementById("addText").value;
      const matiere = document.getElementById("addMat").value;
      //const niveau = document.getElementById("addNv").value;
      const Aeno = document.getElementById("1addEno").value;
      const Ac1 = document.getElementById("1addC1").value;
      const Ac2 = document.getElementById("1addC2").value;
      const Ac3 = document.getElementById("1addC3").value;
      const Ac4 = document.getElementById("1addC4").value;
      const Abonne = document.getElementById("1addBon").value;
      const Beno = document.getElementById("2addEno").value;
      const Bc1 = document.getElementById("2addC1").value;
      const Bc2 = document.getElementById("2addC2").value;
      const Bc3 = document.getElementById("2addC3").value;
      const Bc4 = document.getElementById("2addC4").value;
      const Bbonne = document.getElementById("2addBon").value;
      const Ceno = document.getElementById("3addEno").value;
      const Cc1 = document.getElementById("3addC1").value;
      const Cc2 = document.getElementById("3addC2").value;
      const Cc3 = document.getElementById("3addC3").value;
      const Cc4 = document.getElementById("3addC4").value;
      const Cbonne = document.getElementById("3addBon").value;
      const Deno = document.getElementById("4addEno").value;
      const Dc1 = document.getElementById("4addC1").value;
      const Dc2 = document.getElementById("4addC2").value;
      const Dc3 = document.getElementById("4addC3").value;
      const Dc4 = document.getElementById("4addC4").value;
      const Dbonne = document.getElementById("4addBon").value;
      const formData = new FormData();
      formData.append("file", this.file);
      const AformData = new FormData();
      AformData.append("file", this.Afile);
      if (
        !libelle ||
        !title ||
        !text ||
        !Aeno ||
        !Ac1 ||
        !Ac2 ||
        !Ac3 ||
        !Ac4 ||
        !Abonne ||
        !Beno ||
        !Bc1 ||
        !Bc2 ||
        !Bc3 ||
        !Bc4 ||
        !Bbonne ||
        !Ceno ||
        !Cc1 ||
        !Cc2 ||
        !Cc3 ||
        !Cc4 ||
        !Cbonne ||
        !Deno ||
        !Dc1 ||
        !Dc2 ||
        !Dc3 ||
        !Dc4 ||
        !Dbonne ||
        this.level.length == 0
      ) {
        this.addError = true;
        this.$parent.load = false;
      } else {
        let imagePath;
        for (let i = 0; i < this.level.length; i++) {
          if (this.file.name) {
            imagePath = "images/" + this.file.name;
            const data = {
              type: "comp",
              libelle: libelle,
              title: title,
              text: text,
              matiere: matiere,
              niveau: this.level[i],
              image: imagePath,
              audio: this.Afile.name ? "audios/" + this.Afile.name : "",
              prof: this.user,
            };
            if (this.Afile.name) {
              axios
                .post(
                  "https://api.victory-partners.ma/victory%20api/uploadaudio.php",
                  AformData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((rr) => {
                  axios
                    .post(
                      "https://api.victory-partners.ma/victory%20api/upload.php",
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((rr) => {
                      new Rest()
                        .addAdmin(data)
                        .then((r) => {
                          this.hideAdd();
                          if (r.data.success) {
                            this.snackBar("Comp Added succesfully!!");
                            const id = r.data.id;
                            const sousQ = [];
                            sousQ.push({
                              type: "Scomp",
                              id: id,
                              ennonce: Aeno,
                              c1: Ac1,
                              c2: Ac2,
                              c3: Ac3,
                              c4: Ac4,
                              bonne: Abonne,
                            });
                            sousQ.push({
                              type: "Scomp",
                              id: id,
                              ennonce: Beno,
                              c1: Bc1,
                              c2: Bc2,
                              c3: Bc3,
                              c4: Bc4,
                              bonne: Bbonne,
                            });
                            sousQ.push({
                              type: "Scomp",
                              id: id,
                              ennonce: Ceno,
                              c1: Cc1,
                              c2: Cc2,
                              c3: Cc3,
                              c4: Cc4,
                              bonne: Cbonne,
                            });
                            sousQ.push({
                              type: "Scomp",
                              id: id,
                              ennonce: Deno,
                              c1: Dc1,
                              c2: Dc2,
                              c3: Dc3,
                              c4: Dc4,
                              bonne: Dbonne,
                            });
                            for (const d of sousQ) {
                              new Rest()
                                .addAdmin(d)
                                .then((r) => {
                                  this.hideAdd();
                                  if (r.data.success) {
                                    this.snackBar(r.data.msg);
                                    this.$parent.load = false;
                                    this.$parent.loadd();
                                  } else {
                                    alert(r.data.msg);
                                    this.$parent.load = false;
                                  }
                                })
                                .catch((e) => {
                                  alert("faild to add Sous Question");
                                  this.$parent.load = false;
                                });
                            }
                          } else {
                            alert(r.data.msg);
                            this.$parent.load = false;
                          }
                        })
                        .catch((e) => {
                          alert("faild to add Comp");
                          this.$parent.load = false;
                        });
                    })
                    .catch(() => {
                      alert("faild to upload image");
                      this.$parent.load = false;
                    });
                })
                .catch(() => {
                  alert("faild to upload audio");
                  this.$parent.load = false;
                });
            } else {
              axios
                .post(
                  "https://api.victory-partners.ma/victory%20api/upload.php",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((rr) => {
                  new Rest()
                    .addAdmin(data)
                    .then((r) => {
                      this.hideAdd();
                      if (r.data.success) {
                        this.snackBar("Comp Added succesfully!!");
                        const id = r.data.id;
                        const sousQ = [];
                        sousQ.push({
                          type: "Scomp",
                          id: id,
                          ennonce: Aeno,
                          c1: Ac1,
                          c2: Ac2,
                          c3: Ac3,
                          c4: Ac4,
                          bonne: Abonne,
                        });
                        sousQ.push({
                          type: "Scomp",
                          id: id,
                          ennonce: Beno,
                          c1: Bc1,
                          c2: Bc2,
                          c3: Bc3,
                          c4: Bc4,
                          bonne: Bbonne,
                        });
                        sousQ.push({
                          type: "Scomp",
                          id: id,
                          ennonce: Ceno,
                          c1: Cc1,
                          c2: Cc2,
                          c3: Cc3,
                          c4: Cc4,
                          bonne: Cbonne,
                        });
                        sousQ.push({
                          type: "Scomp",
                          id: id,
                          ennonce: Deno,
                          c1: Dc1,
                          c2: Dc2,
                          c3: Dc3,
                          c4: Dc4,
                          bonne: Dbonne,
                        });
                        for (const d of sousQ) {
                          new Rest()
                            .addAdmin(d)
                            .then((r) => {
                              this.hideAdd();
                              if (r.data.success) {
                                this.snackBar(r.data.msg);
                                this.$parent.load = false;
                                this.$parent.loadd();
                              } else {
                                alert(r.data.msg);
                                this.$parent.load = false;
                              }
                            })
                            .catch((e) => {
                              alert("faild to add Sous Question");
                              this.$parent.load = false;
                            });
                        }
                      } else {
                        alert(r.data.msg);
                        this.$parent.load = false;
                      }
                    })
                    .catch((e) => {
                      alert("faild to add Comp");
                      this.$parent.load = false;
                    });
                })
                .catch(() => {
                  alert("faild to upload image");
                  this.$parent.load = false;
                });
            }
          } else {
            imagePath = "";
            const data = {
              type: "comp",
              libelle: libelle,
              title: title,
              text: text,
              matiere: matiere,
              niveau: this.level[i],
              image: imagePath,
              audio: this.Afile.name ? "audios/" + this.Afile.name : "",
              prof: this.user,
            };
            if (this.Afile.name) {
              axios
                .post(
                  "https://api.victory-partners.ma/victory%20api/uploadaudio.php",
                  AformData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((rr) => {
                  new Rest()
                    .addAdmin(data)
                    .then((r) => {
                      this.hideAdd();
                      if (r.data.success) {
                        this.snackBar("Comp Added succesfully!!");
                        const id = r.data.id;
                        const sousQ = [];
                        sousQ.push({
                          type: "Scomp",
                          id: id,
                          ennonce: Aeno,
                          c1: Ac1,
                          c2: Ac2,
                          c3: Ac3,
                          c4: Ac4,
                          bonne: Abonne,
                        });
                        sousQ.push({
                          type: "Scomp",
                          id: id,
                          ennonce: Beno,
                          c1: Bc1,
                          c2: Bc2,
                          c3: Bc3,
                          c4: Bc4,
                          bonne: Bbonne,
                        });
                        sousQ.push({
                          type: "Scomp",
                          id: id,
                          ennonce: Ceno,
                          c1: Cc1,
                          c2: Cc2,
                          c3: Cc3,
                          c4: Cc4,
                          bonne: Cbonne,
                        });
                        sousQ.push({
                          type: "Scomp",
                          id: id,
                          ennonce: Deno,
                          c1: Dc1,
                          c2: Dc2,
                          c3: Dc3,
                          c4: Dc4,
                          bonne: Dbonne,
                        });
                        for (const d of sousQ) {
                          new Rest()
                            .addAdmin(d)
                            .then((r) => {
                              this.hideAdd();
                              if (r.data.success) {
                                this.snackBar(r.data.msg);
                                this.$parent.load = false;
                                this.$parent.loadd();
                              } else {
                                alert(r.data.msg);
                                this.$parent.load = false;
                              }
                            })
                            .catch((e) => {
                              alert("faild to add Sous Question");
                              this.$parent.load = false;
                            });
                        }
                      } else {
                        alert(r.data.msg);
                        this.$parent.load = false;
                      }
                    })
                    .catch((e) => {
                      alert("faild to add Comp");
                      this.$parent.load = false;
                    });
                })
                .catch(() => {
                  alert("faild to upload audio");
                  this.$parent.load = false;
                });
            } else {
              new Rest()
                .addAdmin(data)
                .then((r) => {
                  this.hideAdd();
                  if (r.data.success) {
                    this.snackBar("Comp Added succesfully!!");
                    const id = r.data.id;
                    const sousQ = [];
                    sousQ.push({
                      type: "Scomp",
                      id: id,
                      ennonce: Aeno,
                      c1: Ac1,
                      c2: Ac2,
                      c3: Ac3,
                      c4: Ac4,
                      bonne: Abonne,
                    });
                    sousQ.push({
                      type: "Scomp",
                      id: id,
                      ennonce: Beno,
                      c1: Bc1,
                      c2: Bc2,
                      c3: Bc3,
                      c4: Bc4,
                      bonne: Bbonne,
                    });
                    sousQ.push({
                      type: "Scomp",
                      id: id,
                      ennonce: Ceno,
                      c1: Cc1,
                      c2: Cc2,
                      c3: Cc3,
                      c4: Cc4,
                      bonne: Cbonne,
                    });
                    sousQ.push({
                      type: "Scomp",
                      id: id,
                      ennonce: Deno,
                      c1: Dc1,
                      c2: Dc2,
                      c3: Dc3,
                      c4: Dc4,
                      bonne: Dbonne,
                    });
                    for (const d of sousQ) {
                      new Rest()
                        .addAdmin(d)
                        .then((r) => {
                          this.hideAdd();
                          if (r.data.success) {
                            this.snackBar(r.data.msg);
                            this.$parent.load = false;
                            this.$parent.loadd();
                          } else {
                            alert(r.data.msg);
                            this.$parent.load = false;
                          }
                        })
                        .catch((e) => {
                          alert("faild to add Sous Question");
                          this.$parent.load = false;
                        });
                    }
                  } else {
                    alert(r.data.msg);
                    this.$parent.load = false;
                  }
                })
                .catch((e) => {
                  alert("faild to add Comp");
                  this.$parent.load = false;
                });
            }
          }
        }
      }
    },
    edit() {
      this.$parent.load = true;
      const libelle = document.getElementById("editLib").value;
      const title = document.getElementById("editTitle").value;
      const text = document.getElementById("editText").value;
      const matiere = document.getElementById("editMat").value;
      const niveau = document.getElementById("editNv").value;
      const Aeno = document.getElementById("1editEno").value;
      const Ac1 = document.getElementById("1editC1").value;
      const Ac2 = document.getElementById("1editC2").value;
      const Ac3 = document.getElementById("1editC3").value;
      const Ac4 = document.getElementById("1editC4").value;
      const Abonne = document.getElementById("1editBon").value;
      const Beno = document.getElementById("2editEno").value;
      const Bc1 = document.getElementById("2editC1").value;
      const Bc2 = document.getElementById("2editC2").value;
      const Bc3 = document.getElementById("2editC3").value;
      const Bc4 = document.getElementById("2editC4").value;
      const Bbonne = document.getElementById("2editBon").value;
      const Ceno = document.getElementById("3editEno").value;
      const Cc1 = document.getElementById("3editC1").value;
      const Cc2 = document.getElementById("3editC2").value;
      const Cc3 = document.getElementById("3editC3").value;
      const Cc4 = document.getElementById("3editC4").value;
      const Cbonne = document.getElementById("3editBon").value;
      const Deno = document.getElementById("4editEno").value;
      const Dc1 = document.getElementById("4editC1").value;
      const Dc2 = document.getElementById("4editC2").value;
      const Dc3 = document.getElementById("4editC3").value;
      const Dc4 = document.getElementById("4editC4").value;
      const Dbonne = document.getElementById("4editBon").value;
      const formData = new FormData();
      formData.append("file", this.file);
      const AformData = new FormData();
      AformData.append("file", this.Afile);
      if (
        !libelle ||
        !title ||
        !text ||
        !Aeno ||
        !Ac1 ||
        !Ac2 ||
        !Ac3 ||
        !Ac4 ||
        !Abonne ||
        !Beno ||
        !Bc1 ||
        !Bc2 ||
        !Bc3 ||
        !Bc4 ||
        !Bbonne ||
        !Ceno ||
        !Cc1 ||
        !Cc2 ||
        !Cc3 ||
        !Cc4 ||
        !Cbonne ||
        !Deno ||
        !Dc1 ||
        !Dc2 ||
        !Dc3 ||
        !Dc4 ||
        !Dbonne
      ) {
        this.editError = true;
        this.$parent.load = false;
      } else {
        let imagePath;
        if (this.file.name) {
          imagePath = "images/" + this.file.name;
          const data = {
            type: "comp",
            id: this.selected.main.idQComp,
            libelle: libelle,
            title: title,
            text: text,
            matiere: matiere,
            niveau: niveau,
            image: imagePath,
            audio: this.Afile.name
              ? "audios/" + this.Afile.name
              : this.selected.main.audio,
          };
          if (this.Afile.name) {
            axios
              .post(
                "https://api.victory-partners.ma/victory%20api/uploadaudio.php",
                AformData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((rr) => {
                axios
                  .post(
                    "https://api.victory-partners.ma/victory%20api/upload.php",
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((rr) => {
                    new Rest()
                      .updateAdmin(data)
                      .then((r) => {
                        this.hideEdit();
                        if (r.data.success) {
                          this.snackBar("Comp edited succesfully!!");
                          const id = r.data.id;
                          const sousQ = [];
                          sousQ.push({
                            type: "Scomp",
                            id: this.selected.sousQues[0].idSousQComp,
                            ennonce: Aeno,
                            c1: Ac1,
                            c2: Ac2,
                            c3: Ac3,
                            c4: Ac4,
                            bonne: Abonne,
                          });
                          sousQ.push({
                            type: "Scomp",
                            id: this.selected.sousQues[1].idSousQComp,
                            ennonce: Beno,
                            c1: Bc1,
                            c2: Bc2,
                            c3: Bc3,
                            c4: Bc4,
                            bonne: Bbonne,
                          });
                          sousQ.push({
                            type: "Scomp",
                            id: this.selected.sousQues[2].idSousQComp,
                            ennonce: Ceno,
                            c1: Cc1,
                            c2: Cc2,
                            c3: Cc3,
                            c4: Cc4,
                            bonne: Cbonne,
                          });
                          sousQ.push({
                            type: "Scomp",
                            id: this.selected.sousQues[3].idSousQComp,
                            ennonce: Deno,
                            c1: Dc1,
                            c2: Dc2,
                            c3: Dc3,
                            c4: Dc4,
                            bonne: Dbonne,
                          });
                          for (const d of sousQ) {
                            new Rest()
                              .updateAdmin(d)
                              .then((r) => {
                                this.hideAdd();
                                if (r.data.success) {
                                  this.snackBar(r.data.msg);
                                  this.$parent.load = false;
                                  this.$parent.loadd();
                                } else {
                                  alert(r.data.msg);
                                  this.$parent.load = false;
                                }
                              })
                              .catch((e) => {
                                alert("faild to edit Sous Question");
                                this.$parent.load = false;
                              });
                          }
                        } else {
                          alert(r.data.msg);
                          this.$parent.load = false;
                        }
                      })
                      .catch((e) => {
                        alert("faild to edit Comp");
                        this.$parent.load = false;
                      });
                  })
                  .catch(() => {
                    alert("faild to upload image");
                    this.$parent.load = false;
                  });
              })
              .catch(() => {
                alert("faild to upload audio");
                this.$parent.load = false;
              });
          } else {
            axios
              .post(
                "https://api.victory-partners.ma/victory%20api/upload.php",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((rr) => {
                new Rest()
                  .updateAdmin(data)
                  .then((r) => {
                    this.hideEdit();
                    if (r.data.success) {
                      this.snackBar("Comp edited succesfully!!");
                      const id = r.data.id;
                      const sousQ = [];
                      sousQ.push({
                        type: "Scomp",
                        id: this.selected.sousQues[0].idSousQComp,
                        ennonce: Aeno,
                        c1: Ac1,
                        c2: Ac2,
                        c3: Ac3,
                        c4: Ac4,
                        bonne: Abonne,
                      });
                      sousQ.push({
                        type: "Scomp",
                        id: this.selected.sousQues[1].idSousQComp,
                        ennonce: Beno,
                        c1: Bc1,
                        c2: Bc2,
                        c3: Bc3,
                        c4: Bc4,
                        bonne: Bbonne,
                      });
                      sousQ.push({
                        type: "Scomp",
                        id: this.selected.sousQues[2].idSousQComp,
                        ennonce: Ceno,
                        c1: Cc1,
                        c2: Cc2,
                        c3: Cc3,
                        c4: Cc4,
                        bonne: Cbonne,
                      });
                      sousQ.push({
                        type: "Scomp",
                        id: this.selected.sousQues[3].idSousQComp,
                        ennonce: Deno,
                        c1: Dc1,
                        c2: Dc2,
                        c3: Dc3,
                        c4: Dc4,
                        bonne: Dbonne,
                      });
                      for (const d of sousQ) {
                        new Rest()
                          .updateAdmin(d)
                          .then((r) => {
                            this.hideAdd();
                            if (r.data.success) {
                              this.snackBar(r.data.msg);
                              this.$parent.load = false;
                              this.$parent.loadd();
                            } else {
                              alert(r.data.msg);
                              this.$parent.load = false;
                            }
                          })
                          .catch((e) => {
                            alert("faild to edit Sous Question");
                            this.$parent.load = false;
                          });
                      }
                    } else {
                      alert(r.data.msg);
                      this.$parent.load = false;
                    }
                  })
                  .catch((e) => {
                    alert("faild to edit Comp");
                    this.$parent.load = false;
                  });
              })
              .catch(() => {
                alert("faild to upload image");
                this.$parent.load = false;
              });
          }
        } else {
          imagePath = this.selected.main.CheminImage;
          const data = {
            type: "comp",
            id: this.selected.main.idQComp,
            libelle: libelle,
            title: title,
            text: text,
            matiere: matiere,
            niveau: niveau,
            image: imagePath,
            audio: this.Afile.name
              ? "audios/" + this.Afile.name
              : this.selected.main.audio,
          };
          if (this.Afile.name) {
            axios
              .post(
                "https://api.victory-partners.ma/victory%20api/uploadaudio.php",
                AformData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((rr) => {
                new Rest()
                  .updateAdmin(data)
                  .then((r) => {
                    this.hideEdit();
                    if (r.data.success) {
                      this.snackBar("Comp Added succesfully!!");
                      const id = r.data.id;
                      const sousQ = [];
                      sousQ.push({
                        type: "Scomp",
                        id: this.selected.sousQues[0].idSousQComp,
                        ennonce: Aeno,
                        c1: Ac1,
                        c2: Ac2,
                        c3: Ac3,
                        c4: Ac4,
                        bonne: Abonne,
                      });
                      sousQ.push({
                        type: "Scomp",
                        id: this.selected.sousQues[1].idSousQComp,
                        ennonce: Beno,
                        c1: Bc1,
                        c2: Bc2,
                        c3: Bc3,
                        c4: Bc4,
                        bonne: Bbonne,
                      });
                      sousQ.push({
                        type: "Scomp",
                        id: this.selected.sousQues[2].idSousQComp,
                        ennonce: Ceno,
                        c1: Cc1,
                        c2: Cc2,
                        c3: Cc3,
                        c4: Cc4,
                        bonne: Cbonne,
                      });
                      sousQ.push({
                        type: "Scomp",
                        id: this.selected.sousQues[3].idSousQComp,
                        ennonce: Deno,
                        c1: Dc1,
                        c2: Dc2,
                        c3: Dc3,
                        c4: Dc4,
                        bonne: Dbonne,
                      });
                      for (const d of sousQ) {
                        new Rest()
                          .updateAdmin(d)
                          .then((r) => {
                            this.hideEdit();
                            if (r.data.success) {
                              this.snackBar(r.data.msg);
                              this.$parent.load = false;
                              this.$parent.loadd();
                            } else {
                              alert(r.data.msg);
                              this.$parent.load = false;
                            }
                          })
                          .catch((e) => {
                            alert("faild to edit Sous Question");
                            this.$parent.load = false;
                          });
                      }
                    } else {
                      alert(r.data.msg);
                      this.$parent.load = false;
                    }
                  })
                  .catch((e) => {
                    alert("faild to edit Comp");
                    this.$parent.load = false;
                  });
              })
              .catch(() => {
                alert("faild to upload audio");
                this.$parent.load = false;
              });
          } else {
            new Rest()
              .updateAdmin(data)
              .then((r) => {
                this.hideEdit();
                if (r.data.success) {
                  this.snackBar("Comp edited succesfully!!");
                  const id = r.data.id;
                  const sousQ = [];
                  sousQ.push({
                    type: "Scomp",
                    id: this.selected.sousQues[0].idSousQComp,
                    ennonce: Aeno,
                    c1: Ac1,
                    c2: Ac2,
                    c3: Ac3,
                    c4: Ac4,
                    bonne: Abonne,
                  });
                  sousQ.push({
                    type: "Scomp",
                    id: this.selected.sousQues[1].idSousQComp,
                    ennonce: Beno,
                    c1: Bc1,
                    c2: Bc2,
                    c3: Bc3,
                    c4: Bc4,
                    bonne: Bbonne,
                  });
                  sousQ.push({
                    type: "Scomp",
                    id: this.selected.sousQues[2].idSousQComp,
                    ennonce: Ceno,
                    c1: Cc1,
                    c2: Cc2,
                    c3: Cc3,
                    c4: Cc4,
                    bonne: Cbonne,
                  });
                  sousQ.push({
                    type: "Scomp",
                    id: this.selected.sousQues[3].idSousQComp,
                    ennonce: Deno,
                    c1: Dc1,
                    c2: Dc2,
                    c3: Dc3,
                    c4: Dc4,
                    bonne: Dbonne,
                  });
                  for (const d of sousQ) {
                    new Rest()
                      .updateAdmin(d)
                      .then((r) => {
                        this.hideAdd();
                        if (r.data.success) {
                          this.snackBar(r.data.msg);
                          this.$parent.load = false;
                          this.$parent.loadd();
                        } else {
                          alert(r.data.msg);
                          this.$parent.load = false;
                        }
                      })
                      .catch((e) => {
                        alert("faild to update Sous Question");
                        this.$parent.load = false;
                      });
                  }
                } else {
                  alert(r.data.msg);
                  this.$parent.load = false;
                }
              })
              .catch((e) => {
                alert("faild to update Comp");
                this.$parent.load = false;
              });
          }
        }
      }
    },
    searchh() {
      let curr = 0;
      const M = document.getElementById("Msearch").value;
      const N = document.getElementById("Nsearch").value;
      const P = document.getElementById("Psearch").value;
      const txt = document.getElementById("search").value.toLowerCase().trim();
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        if (M != "M" && N != "N" && P == "P") {
          if (
            t.children[5].className == N &&
            t.children[4].className == M &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M != "M" && N == "N" && P == "P") {
          if (
            t.children[4].className == M &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M == "M" && N != "N" && P == "P") {
          if (
            t.children[5].className == N &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M != "M" && N != "N" && P != "P") {
          if (
            t.children[5].className == N &&
            t.children[4].className == M &&
            t.children[6].className == P &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M != "M" && N == "N" && P != "P") {
          if (
            t.children[4].className == M &&
            t.children[6].className == P &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M == "M" && N != "N" && P != "P") {
          if (
            t.children[5].className == N &&
            t.children[6].className == P &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M == "M" && N == "N" && P != "P") {
          if (
            t.children[6].className == P &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M == "M" && N == "N" && P == "P") {
          if (t.innerText.toLowerCase().indexOf(txt) != -1) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
    },
    filter() {
      let curr = 0;
      const s = document.getElementById("search").value.toLowerCase().trim();
      const P = document.getElementById("Psearch").value;
      const M = document.getElementById("Msearch").value;
      const N = document.getElementById("Nsearch").value;
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        if (s == "") {
          if (N != "N" && t.children[5].className != N) {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          } else if (M != "M" && t.children[4].className != M) {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          } else if (P != "P" && t.children[6].className != P) {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          } else {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          }
        } else {
          if (M != "M" && N != "N" && P == "P") {
            if (
              t.children[5].className == N &&
              t.children[4].className == M &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M != "M" && N == "N" && P == "P") {
            if (
              t.children[4].className == M &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M == "M" && N != "N" && P == "P") {
            if (
              t.children[5].className == N &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M != "M" && N != "N" && P != "P") {
            if (
              t.children[5].className == N &&
              t.children[4].className == M &&
              t.children[6].className == P &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M != "M" && N == "N" && P != "P") {
            if (
              t.children[4].className == M &&
              t.children[6].className == P &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M == "M" && N != "N" && P != "P") {
            if (
              t.children[5].className == N &&
              t.children[6].className == P &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M == "M" && N == "N" && P != "P") {
            if (
              t.children[6].className == P &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M == "M" && N == "N" && P == "P") {
            if (t.innerText.toLowerCase().indexOf(s) != -1) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          }
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
    },
    chooseImg(event) {
      console.log(event.target.files[0].type);
      if (
        event.target.files[0].type != "image/jpeg" &&
        event.target.files[0].type != "image/png"
      ) {
        event.target.value = "";
        alert("Accept .PNG and .JPEG files only");
      } else {
        this.file = event.target.files[0];
      }
    },
    selectImage(event) {
      document.getElementsByName("selected").forEach((rr) => {
        if (!rr.checked) {
          rr.parentNode.parentNode.className = "";
        }
      });
      if (event.target.checked) {
        event.target.parentNode.parentNode.className = "selectedImg";
      } else {
        event.target.parentNode.parentNode.className = "";
      }
    },
    chooseImage() {
      document.getElementsByName("selected").forEach((rr) => {
        if (rr.checked) {
          this.selectedImg = rr.value;
          return;
        }
      });
      this.hideImg();
    },
    chooseAudio(event) {
      console.log(event.target.files[0].type);
      if (event.target.files[0].type != "audio/mpeg") {
        event.target.value = "";
        alert("Accept audio files only");
      } else this.Afile = event.target.files[0];
    },
  },
  beforeMount() {
    this.$parent.load = true;
    new Rest().getAllNiveaux().then((r) => {
      this.niveaux = r.data.data;
    });
  },
  mounted() {
    this.$parent.load = false;
  },
};
</script>

<style>
.hiddden{
  display: none;
}


@media screen and (max-width: 1200px) {
  #customers {
    font-size: 13px;
  }
  #customers td,
  #customers th {
    padding: 3px !important;
  }
  .toolbox {
    display: block !important;
  }
  .searchbar {
    width: fit-content !important;
    margin: 5px auto !important;
  }
}
@media screen and (max-width: 700px) {
  #customers td,
  #customers th {
    padding: 2px !important;
  }
  .Acomp .hiddenCol {
    display: none !important;
  }
  .sousQcus {
    width: 100% !important;
  }
  .searchbar {
    display: block !important;
    text-align: center !important;
  }
}
.TableTitle {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: auto;
  padding-top: 20px;
  font-size: 30px;
  font-weight: bold;
}
.TableTitle img {
  margin-right: 10px;
}
.Acomp {
  position: relative;
  height: 100%;
}
.customersCnt {
  height: calc(100% - 133px);
  max-height: calc(100% - 133px);
}
.tablesScroll {
  overflow-y: scroll;
  height: calc(100vh - 268px);
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 99.9%;
  margin: auto;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 30px;
  overflow-wrap: break-word;
  min-width: 30px;
  align-items: center;
}
tr {
  display: inline-table;
  width: 99.9%;
  cursor: pointer;
}
thead tr {
  display: inline-table !important;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #bb1013;
  color: white;
}
.toolbox {
  padding: 10px;
  background-color: #909090;
  display: flex;
}
.searchbar {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.searchbar input,
select {
  border-radius: 20px;
  padding: 5px;
  font-size: 15px;
}
.searchbar select {
  margin: 0 10px;
  width: 170px;
}
.searchbar input::placeholder {
  text-align: center;
}
.searchbar img {
  margin-right: 5px;
}
.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 7px 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 25px;
}
.add {
  background-color: rgb(77, 214, 43);
}
.delete {
  background-color: red;
}
.edit {
  background-color: DodgerBlue;
}
.add:hover {
  background-color: #4dd62b9e;
}
.delete:hover {
  background-color: #ff00009e;
}
.edit:hover {
  background-color: #1e90ff7d;
}
.toolPanel {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff85;
  z-index: 2;
  display: flex;
  align-items: center;
}
.toolPanel .cc {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  width: 50%;
  margin: auto;
  padding: 20px 50px;
  border-radius: 20px;
  position: relative;
  background-image: linear-gradient(
    to right,
    #a2c5dd,
    #8fb5d0,
    #7ca6c4,
    #6a97b7,
    #5788ab,
    #5788ab,
    #5788ab,
    #5788ab,
    #6a97b7,
    #7ca6c4,
    #8fb5d0,
    #a2c5dd
  );
}
.cc .ccTitle {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}
.cc .ccChild {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 5px;
  font-weight: bold;
}
.ccChild span {
  display: flex;
  align-items: center;
}
.ccChild input,
select {
  padding: 5px;
  font-size: 15px;
}
.ccChild textarea {
  padding: 5px;
  font-size: 15px;
  width: 100%;
}
.x {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  border-top-right-radius: 20px;
  cursor: pointer;
}
.x:hover {
  background-color: red;
}
.enable {
  cursor: no-drop;
}
.selected {
  background-color: #7777ffa1 !important;
}
.selected:hover {
  background-color: #7777ffa1 !important;
}
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
/* Darker background on mouse-over */
.import {
  height: fit-content;
  background-color: #ffffffb0;
  border-radius: 20px;
  border: dashed 1px;
  position: relative;
}
.import span {
  position: absolute;
  width: 100%;
  text-align: center;
  display: block;
}
.import input {
  width: 100%;
  padding: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  padding: 15px 0;
}
.child {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  max-height: 500px;
  overflow-y: auto;
}
.selectedImg {
  border: 3px solid white !important;
}
.img:hover {
  color: white !important;
}
.enabled {
  cursor: no-drop !important;
}
</style>
