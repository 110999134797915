<template>
  <div class="Aqcm">
    <div id="snackbar"></div>
    <div v-if="panelImg" class="toolPanel" style="z-index: 3">
      <div class="cc" style="">
        <div class="x img" style="color: red" v-on:click="hideImg">X</div>
        <div class="ccTitle">Choose image from server</div>
        <div class="child" style="">
          <div
            v-for="i in images"
            :key="i"
            style="max-width: 120px; min-width: 100px; margin: 5px; border: 1px dashed"
          >
            <img
              style="width: 100%"
              :src="'https://api.victory-partners.ma/victory%20api/upload/' + i"
              alt=""
            />
            <div style="text-align: center">
              <input
                type="radio"
                v-on:change="selectImage"
                name="selected"
                :value="i"
              /><span style="margin-left: 5px; overflow-wrap: break-word">{{
                i.split("/")[1]
              }}</span>
            </div>
          </div>
        </div>
        <div style="text-align: center; margin-top: 15px">
          <button style="font-size: 18px" @click="chooseImage" class="btn edit">
            Choose :<i style="margin-left: 5px; font-size: 25px" class="fa fa-image"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelAdd" class="toolPanel">
      <div class="cc">
        <div class="x" v-on:click="hideAdd">X</div>
        <div class="ccTitle">Add QCM</div>
        <div class="ccChild" style="display: inline">
          <span>Libelle:</span><textarea id="addLib"></textarea>
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span><textarea id="addEno"></textarea>
        </div>
        <div class="ccChild"><span>Choix 1:</span><input id="addC1" type="text" /></div>
        <div class="ccChild"><span>Choix 2:</span><input id="addC2" type="text" /></div>
        <div class="ccChild"><span>Choix 3:</span><input id="addC3" type="text" /></div>
        <div class="ccChild"><span>Choix 4:</span><input id="addC4" type="text" /></div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="addBon">
            <option value="A">A</option>
            <option value="B">B</option>
            <option value="C">C</option>
            <option value="D">D</option>
          </select>
        </div>
        <div class="ccChild">
          <span>Matiére:</span>
          <select id="addMat">
            <option
              v-for="m of matiers"
              :key="m.idMatiere"
              :value="m.idMatiere"
            >
              {{m.LibelleMatiere}}
            </option>
          </select>
        </div>
        <div class="ccChild" style="grid-template-columns: 30% 70%">
          <span>Niveau:</span>
          <span
            style="display: grid; grid-template-columns: 50% 50%; font-weight: normal"
          >
            <span v-for="n in niveaux" :key="n.idNiveau" style="padding: 5px">
              <input type="checkbox" @click="selectLevel(n.idNiveau)" />
              {{ n.LibelleNiveau }}
            </span>
          </span>
        </div>
        <div class="ccChild">
          <span>Import Image:</span>
          <div class="import" @click="file.name != null ? (file = {}) : null">
            <span>
              <i
                v-if="file.name == null"
                style="font-size: 25px"
                class="fa fa-upload"
              ></i>
              <i
                v-if="file.name != null"
                style="font-size: 25px; color: red"
                class="fa fa-close"
              ></i>
              <div>{{ file.name != null ? file.name : "No File chosen" }}</div>
            </span>
            <input
              @change="chooseImg"
              type="file"
              :style="file.name != null ? 'width:0' : ''"
              :disabled="selectedImg || file.name != null ? true : false"
              :class="selectedImg ? 'enabled' : ''"
              id="addImage"
              accept="image/png, image/jpeg"
            />
          </div>
        </div>
        <div v-if="addError" style="color: yellow; text-align: center">
          Some inputs required!
        </div>
        <div style="text-align: center">
          <button v-on:click="add" class="btn add">
            <i class="fa fa-plus-square"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelSupp" class="toolPanel">
      <div class="cc">
        <div class="ccTitle" style="color: red">
          Do you really want to delete QCM(id:'{{ selected.idQCM }}')?
        </div>
        <div style="text-align: center">
          <button v-on:click="delet" class="btn delete">
            <i class="fa fa-trash"></i>
          </button>
          <button v-on:click="hideSupp" class="btn edit">
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-if="panelEdit" class="toolPanel">
      <div class="cc">
        <div class="x" v-on:click="hideEdit">X</div>
        <div class="ccTitle">Edit QCM</div>
        <div class="ccChild" style="grid-template-columns: 20% 80%">
          <span style="color: red">Prof:</span
          >{{ selected.prof ? selected.prof : "null" }}
        </div>
        <div class="ccChild" style="display: inline">
          <span>Libelle:</span
          ><textarea rows="3" id="editLib" :value="selected.LibelleQCM"></textarea>
        </div>
        <div class="ccChild" style="display: inline">
          <span>Enoncé:</span
          ><textarea rows="3" id="editEno" :value="selected.EnonceQCM"></textarea>
        </div>
        <div class="ccChild">
          <span>Choix 1:</span
          ><input id="editC1" type="text" :value="selected.Choix1QCM" />
        </div>
        <div class="ccChild">
          <span>Choix 2:</span
          ><input id="editC2" type="text" :value="selected.Choix2QCM" />
        </div>
        <div class="ccChild">
          <span>Choix 3:</span
          ><input id="editC3" type="text" :value="selected.Choix3QCM" />
        </div>
        <div class="ccChild">
          <span>Choix 4:</span
          ><input id="editC4" type="text" :value="selected.Choix4QCM" />
        </div>
        <div class="ccChild">
          <span>Bonne Reponse:</span>
          <select id="editBon">
            <option :selected="selected.BonneReponseQCM == 'A' ? true : false" value="A">
              A
            </option>
            <option :selected="selected.BonneReponseQCM == 'B' ? true : false" value="B">
              B
            </option>
            <option :selected="selected.BonneReponseQCM == 'C' ? true : false" value="C">
              C
            </option>
            <option :selected="selected.BonneReponseQCM == 'D' ? true : false" value="D">
              D
            </option>
          </select>
        </div>
        <div class="ccChild">
          <span>Matiére:</span>
          <select id="editMat">
            <option
              v-for="m of matiers"
              :key="m.idMatiere"
              :value="m.idMatiere"
              :selected="selected.Matiere_idMatiere == m.idMatiere ? true : false"
            >
              {{m.LibelleMatiere}}
            </option>
          </select>
        </div>
        <div class="ccChild">
          <span>Niveau:</span>
          <select id="editNv">
            <option
              v-for="n in niveaux"
              :key="n.idNiveau"
              :value="n.idNiveau"
              :selected="selected.Niveau_idNiveau == n.idNiveau ? true : false"
            >
              {{ n.LibelleNiveau }}
            </option>
          </select>
        </div>
        <div class="ccChild">
          <span>current image:</span>
          <img
            style="width: 150px"
            :src="
              'https://api.victory-partners.ma/victory%20api/upload/' +
              selected.CheminImage
            "
            alt=""
          />
        </div>
        <div>Edit image? : <input v-model="editImage" type="checkbox" /></div>
        <div v-if="editImage">
          <div class="ccChild">
            <span>Import Image:</span>
            <div class="import" @click="file.name != null ? (file = {}) : null">
              <span>
                <i
                  v-if="file.name == null"
                  style="font-size: 25px"
                  class="fa fa-upload"
                ></i>
                <i
                  v-if="file.name != null"
                  style="font-size: 25px; color: red"
                  class="fa fa-close"
                ></i>
                <div>{{ file.name != null ? file.name : "No File chosen" }}</div>
              </span>
              <input
                @change="chooseImg"
                type="file"
                :style="file.name != null ? 'width:0' : ''"
                :disabled="selectedImg || file.name != null ? true : false"
                :class="selectedImg ? 'enabled' : ''"
                id="addImage"
                accept="image/png, image/jpeg"
              />
            </div>
          </div>
        </div>
        <div v-if="editError" style="color: yellow; text-align: center">
          Some inputs required!
        </div>
        <div style="text-align: center">
          <button v-on:click="edit" class="btn edit"><i class="fa fa-edit"></i></button>
          <button v-if="selected.valide == 0 && role == 'admin'" v-on:click="confirmer" class="btn add">confirmer</button>
        </div>
      </div>
    </div>
    <div class="TableTitle">
      <img src="@/assets/icons/qcm.png" width="50" alt="" /> QCM (
      <span id="Totcurr">{{ total }}</span> {{ "/" + total }})
    </div>
    <div class="toolbox">
      <div style="margin: auto; text-align: center">
        <button v-on:click="showAdd" class="btn add">
          <i class="fa fa-plus-square"></i>
        </button>
        <button
          v-on:click="showSupp"
          :disabled="selected.idQCM ? false : true"
          :class="!selected.idQCM ? 'btn delete enable' : 'btn delete'"
        >
          <i class="fa fa-trash"></i>
        </button>
        <button
          v-on:click="showEdit"
          :disabled="selected.idQCM ? false : true"
          :class="!selected.idQCM ? 'btn edit enable' : 'btn edit'"
        >
          <i class="fa fa-edit"></i>
        </button>
      </div>
      <div class="searchbar">
        <select id="Psearch" @change="filter">
          <option value="P">Filter by Prof</option>
          <option v-for="u in users" :key="u.username" :value="'P' + u.username">
            {{ u.username }}
          </option>
        </select>
        <select id="Msearch" @change="filter">
          <option value="M">Filter by Matiére</option>
          <option
              v-for="m of matiers"
              :key="m.idMatiere"
              :value="'M'+m.idMatiere"
            >
              {{m.LibelleMatiere}}
            </option>
        </select>
        <select id="Nsearch" @change="filter">
          <option value="N">Filter by Niveau</option>
          <option v-for="n in niveaux" :key="n.idNiveau" :value="'N' + n.idNiveau">
            {{ n.LibelleNiveau }}
          </option>
        </select>
        <img src="@/assets/icons/search.png" width="25" alt="" />
        <input type="text" id="search" @keyup="searchh" placeholder="search" />
      </div>
    </div>
    <div class="customersCnt">
      <table id="customers">
        <thead>
          <tr style="cursor: initial">
            <th class="hiddenCol">Id QCM</th>
            <th>Libelle</th>
            <th>Enoncé</th>
            <th class="hiddenCol">Choix 1</th>
            <th class="hiddenCol">Choix 2</th>
            <th class="hiddenCol">Choix 3</th>
            <th class="hiddenCol">Choix 4</th>
            <th>Bonne Reponse</th>
            <th>Matiére</th>
            <th>Niveau</th>
            <th>Prof</th>
            <th>Image</th>
            <th class="hiddenCol" style="padding: 0; width: 15px; min-width: 0px"></th>
          </tr>
        </thead>
        <div class="tablesScroll">
          <tr v-for="d in data" :key="d.idQCM" v-on:click="select(d)" :id="d.idQCM" :style="d.valide==0?'background-color:#f3f390':''">
            <td class="hiddenCol">{{ d.idQCM ? d.idQCM : "null" }}</td>
            <td>{{ d.LibelleQCM ? d.LibelleQCM.substr(0, 50) : "null" }} </td>
            <td>{{ d.EnonceQCM ? d.EnonceQCM.substr(0, 50) : "null" }}</td>
            <td class="hiddenCol">{{ d.Choix1QCM ? d.Choix1QCM : "null" }}</td>
            <td class="hiddenCol">{{ d.Choix2QCM ? d.Choix2QCM : "null" }}</td>
            <td class="hiddenCol">{{ d.Choix3QCM ? d.Choix3QCM : "null" }}</td>
            <td class="hiddenCol">{{ d.Choix4QCM ? d.Choix4QCM : "null" }}</td>
            <td>{{ d.BonneReponseQCM ? d.BonneReponseQCM : "null" }}</td>
            <td :class="'M' + d.Matiere_idMatiere">
              {{ d.LibelleMatiere ? d.LibelleMatiere : "null" }}
            </td>
            <td :class="'N' + d.Niveau_idNiveau">
              {{ d.LibelleNiveau ? d.LibelleNiveau : "null" }}
            </td>
            <td :class="'P' + d.prof">
              {{ d.prof ? d.prof : "null" }}
            </td>
            <td style="text-align: center; padding: 0 8px">
              <img
                style="height: 70px"
                :src="
                  d.CheminImage
                    ? 'https://api.victory-partners.ma/victory%20api/upload/' +
                      d.CheminImage
                    : '../assets/Q.jpg'
                "
                alt=""
              />
            </td>
          </tr>
        </div>
      </table>
    </div>
  </div>
</template>

<script>
import { Rest } from "@/providers/rest";
import axios from "axios";

export default {
  name: "Qcm",
  data() {
    return {
      panelAdd: false,
      panelImg: false,
      panelEdit: false,
      selected: {},
      editError: false,
      addError: false,
      panelSupp: false,
      file: {},
      images: [],
      selectedImg: null,
      niveaux: [],
      editImage: false,
      level: [],
    };
  },
  props: ["data", "user", "users", "total","role",'matiers'],
  methods: {
    confirmer(){
      this.$parent.load = true;
      new Rest()
        .confirmerQuestion('qcm',this.selected.idQCM)
        .then((r) => {
          this.hideEdit();
          if (r.data.success) {
            this.snackBar(r.data.msg);
            this.select(this.selected);
            this.$parent.load = false;
            this.$parent.loadd();
          } else {
            alert(r.data.msg);
            this.$parent.load = false;
          }
        })
        .catch((e) => {
          alert(e);
          this.$parent.load = false;
        });
    },
    selectLevel: function (id) {
      if (this.level.indexOf(id) != -1) {
        this.level.splice(this.level.indexOf(id), 1);
      } else {
        this.level.push(id);
      }
    },
    hideAdd: function () {
      this.panelAdd = false;
      this.file = {};
      this.selectedImg = null;
      this.level = [];
    },
    showAdd: function () {
      this.panelAdd = true;
    },
    hideImg: function () {
      this.panelImg = false;
    },
    showImg: function () {
      this.panelImg = true;
    },
    hideEdit: function () {
      this.panelEdit = false;
      this.file = {};
      this.selectedImg = null;
      this.level = [];
    },
    showEdit: function () {
      this.panelEdit = true;
    },
    hideSupp: function () {
      this.panelSupp = false;
    },
    showSupp: function () {
      this.panelSupp = true;
    },
    delet() {
      this.$parent.load = true;
      const data = {
        type: "qcm",
        id: this.selected.idQCM,
      };
      new Rest()
        .deleteAdmin(data)
        .then((r) => {
          this.hideSupp();
          if (r.data.success) {
            this.snackBar(r.data.msg);
            this.select(this.selected);
            this.$parent.load = false;
            this.$parent.loadd();
          } else {
            alert(r.data.msg);
            this.$parent.load = false;
          }
        })
        .catch((e) => {
          alert(e);
          this.$parent.load = false;
        });
    },
    select(d) {
      if (this.selected.idQCM == d.idQCM) {
        this.selected = {};
        document.getElementById(d.idQCM).className = "";
      } else {
        this.selected = d;
        for (const selected of document.getElementsByClassName("selected")) {
          selected.className = "";
        }
        document.getElementById(d.idQCM).className = "selected";
      }
    },
    edit() {
      this.$parent.load = true;
      const libelle = document.getElementById("editLib").value;
      const ennonce = document.getElementById("editEno").value;
      const c1 = document.getElementById("editC1").value;
      const c2 = document.getElementById("editC2").value;
      const c3 = document.getElementById("editC3").value;
      const c4 = document.getElementById("editC4").value;
      const bonne = document.getElementById("editBon").value;
      const matiere = document.getElementById("editMat").value;
      const niveau = document.getElementById("editNv").value;
      const formData = new FormData();
      formData.append("file", this.file);
      if (!libelle || !ennonce || !c1 || !c2 || !c3 || !c4 || !bonne) {
        this.editError = true;
        this.$parent.load = false;
      } else if (!this.editImage) {
        const data = {
          type: "qcm",
          id: this.selected.idQCM,
          libelle: libelle,
          ennonce: ennonce,
          c1: c1,
          c2: c2,
          c3: c3,
          c4: c4,
          bonne: bonne,
          matiere: matiere,
          niveau: niveau,
          image: this.selected.CheminImage,
        };
        new Rest()
          .updateAdmin(data)
          .then((r) => {
            this.hideEdit();
            if (r.data.success) {
              this.snackBar(r.data.msg);
              this.select(this.selected);
              this.$parent.load = false;
              this.$parent.loadd();
            } else {
              alert(r.data.msg);
              this.$parent.load = false;
            }
          })
          .catch((e) => {
            alert(e);
            this.$parent.load = false;
          });
      } else if (this.editImage && !this.selectedImg && !this.file.name) {
        this.editError = true;
        this.$parent.load = false;
      } else {
        let imagePath;
        if (this.file.name) {
          imagePath = "images/" + this.file.name;
          const data = {
            type: "qcm",
            id: this.selected.idQCM,
            libelle: libelle,
            ennonce: ennonce,
            c1: c1,
            c2: c2,
            c3: c3,
            c4: c4,
            bonne: bonne,
            matiere: matiere,
            niveau: niveau,
            image: imagePath,
          };
          axios
            .post("https://api.victory-partners.ma/victory%20api/upload.php", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((rr) => {
              new Rest()
                .updateAdmin(data)
                .then((r) => {
                  this.hideEdit();
                  if (r.data.success) {
                    this.snackBar(r.data.msg);
                    this.select(this.selected);
                    this.$parent.load = false;
                    this.$parent.loadd();
                  } else {
                    alert(r.data.msg);
                    this.$parent.load = false;
                  }
                })
                .catch((e) => {
                  alert(e);
                  this.$parent.load = false;
                });
            })
            .catch(() => {
              alert("faild to upload image");
              this.$parent.load = false;
            });
        } else {
          imagePath = this.selectedImg;
          const data = {
            type: "qcm",
            id: this.selected.idQCM,
            libelle: libelle,
            ennonce: ennonce,
            c1: c1,
            c2: c2,
            c3: c3,
            c4: c4,
            bonne: bonne,
            matiere: matiere,
            niveau: niveau,
            image: imagePath,
          };
          new Rest()
            .updateAdmin(data)
            .then((r) => {
              this.hideEdit();
              if (r.data.success) {
                this.snackBar(r.data.msg);
                this.select(this.selected);
                this.$parent.load = false;
                this.$parent.loadd();
              } else {
                alert(r.data.msg);
                this.$parent.load = false;
              }
            })
            .catch((e) => {
              alert(e);
              this.$parent.load = false;
            });
        }
      }
    },
    snackBar: function (msg) {
      const x = document.getElementById("snackbar");
      if (x) {
        x.className = "show";
        x.innerText = msg;
        setTimeout(function () {
          if (x) {
            x.className = x.className.replace("show", "");
          }
        }, 5000);
      }
    },
    add() {
      this.$parent.load = true;
      const libelle = document.getElementById("addLib").value;
      const ennonce = document.getElementById("addEno").value;
      const c1 = document.getElementById("addC1").value;
      const c2 = document.getElementById("addC2").value;
      const c3 = document.getElementById("addC3").value;
      const c4 = document.getElementById("addC4").value;
      const bonne = document.getElementById("addBon").value;
      const matiere = document.getElementById("addMat").value;
      //const niveau = document.getElementById("addNv").value;
      const formData = new FormData();
      formData.append("file", this.file);
      if (
        !libelle ||
        !ennonce ||
        !c1 ||
        !c2 ||
        !c3 ||
        !c4 ||
        !bonne ||
        this.level.length == 0
      ) {
        this.addError = true;
        this.$parent.load = false;
      } else if (!this.file.name) {
        this.addError = true;
        this.$parent.load = false;
      } else {
        let imagePath;
        for (let i = 0; i < this.level.length; i++) {
          if (this.file.name) {
            imagePath = "images/" + this.file.name;
            const data = {
              type: "qcm",
              libelle: libelle,
              ennonce: ennonce,
              c1: c1,
              c2: c2,
              c3: c3,
              c4: c4,
              bonne: bonne,
              matiere: matiere,
              niveau: this.level[i],
              image: imagePath,
              prof: this.user,
            };
            axios
              .post(
                "https://api.victory-partners.ma/victory%20api/upload.php",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((rr) => {
                new Rest()
                  .addAdmin(data)
                  .then((r) => {
                    this.hideAdd();
                    if (r.data.success) {
                      this.snackBar(r.data.msg);
                      this.$parent.load = false;
                      this.$parent.loadd();
                    } else {
                      alert(r.data.msg);
                      this.$parent.load = false;
                    }
                  })
                  .catch((e) => {
                    alert("faild to add QCM");
                    this.$parent.load = false;
                  });
              })
              .catch(() => {
                alert("faild to upload image");
                this.$parent.load = false;
              });
          } else {
            imagePath = this.selectedImg;
            const data = {
              type: "qcm",
              libelle: libelle,
              ennonce: ennonce,
              c1: c1,
              c2: c2,
              c3: c3,
              c4: c4,
              bonne: bonne,
              matiere: matiere,
              niveau: this.level[i],
              image: imagePath,
              prof: this.user,
            };
            console.log(data);
            new Rest()
              .addAdmin(data)
              .then((r) => {
                console.log(r);
                this.hideAdd();
                if (r.data.success) {
                  this.snackBar(r.data.msg);
                  this.$parent.load = false;
                  this.$parent.loadd();
                } else {
                  alert(r.data.msg);
                  this.$parent.load = false;
                }
              })
              .catch((e) => {
                alert("faild to add");
                this.$parent.load = false;
              });
          }
        }
      }
    },
    searchh() {
      let curr = 0;
      const txt = document.getElementById("search").value.toLowerCase().trim();
      const P = document.getElementById("Psearch").value;
      const M = document.getElementById("Msearch").value;
      const N = document.getElementById("Nsearch").value;
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        if (M != "M" && N != "N" && P == "P") {
          if (
            t.children[9].className == N &&
            t.children[8].className == M &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M != "M" && N == "N" && P == "P") {
          if (
            t.children[8].className == M &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M == "M" && N != "N" && P == "P") {
          if (
            t.children[9].className == N &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M != "M" && N != "N" && P != "P") {
          if (
            t.children[9].className == N &&
            t.children[8].className == M &&
            t.children[10].className == P &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M != "M" && N == "N" && P != "P") {
          if (
            t.children[8].className == M &&
            t.children[10].className == P &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M == "M" && N != "N" && P != "P") {
          if (
            t.children[9].className == N &&
            t.children[10].className == P &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M == "M" && N == "N" && P != "P") {
          if (
            t.children[10].className == P &&
            t.innerText.toLowerCase().indexOf(txt) != -1
          ) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        } else if (M == "M" && N == "N" && P == "P") {
          if (t.innerText.toLowerCase().indexOf(txt) != -1) {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          } else {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          }
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
      //this.filter()
    },
    chooseImg(event) {
      console.log(event.target.files[0].type);
      if (
        event.target.files[0].type != "image/jpeg" &&
        event.target.files[0].type != "image/png"
      ) {
        event.target.value = "";
        alert("Accept .PNG and .JPEG files only");
      } else {
        this.file = event.target.files[0];
      }
    },
    filter() {
      let curr = 0;
      const s = document.getElementById("search").value.toLowerCase().trim();
      const P = document.getElementById("Psearch").value;
      const M = document.getElementById("Msearch").value;
      const N = document.getElementById("Nsearch").value;
      const tr = document.getElementsByTagName("tr");
      for (const t of tr) {
        if (s == "") {
          if (N != "N" && t.children[9].className != N) {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          } else if (M != "M" && t.children[8].className != M) {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          } else if (P != "P" && t.children[10].className != P) {
            if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
          } else {
            t.className = t.className.replace(" hiddden", "");
            curr++;
          }
        } else {
          if (M != "M" && N != "N" && P == "P") {
            if (
              t.children[9].className == N &&
              t.children[8].className == M &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M != "M" && N == "N" && P == "P") {
            if (
              t.children[8].className == M &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M == "M" && N != "N" && P == "P") {
            if (
              t.children[9].className == N &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M != "M" && N != "N" && P != "P") {
            if (
              t.children[9].className == N &&
              t.children[8].className == M &&
              t.children[10].className == P &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M != "M" && N == "N" && P != "P") {
            if (
              t.children[8].className == M &&
              t.children[10].className == P &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M == "M" && N != "N" && P != "P") {
            if (
              t.children[9].className == N &&
              t.children[10].className == P &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M == "M" && N == "N" && P != "P") {
            if (
              t.children[10].className == P &&
              t.innerText.toLowerCase().indexOf(s) != -1
            ) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          } else if (M == "M" && N == "N" && P == "P") {
            if (t.innerText.toLowerCase().indexOf(s) != -1) {
              t.className = t.className.replace(" hiddden", "");
              curr++;
            } else {
              if(t.className.indexOf(" hiddden")==-1)
              t.className += " hiddden";
            }
          }
        }
      }
      document.getElementById("Totcurr").innerHTML = curr;
    },
    selectImage(event) {
      document.getElementsByName("selected").forEach((rr) => {
        if (!rr.checked) {
          rr.parentNode.parentNode.className = "";
        }
      });
      if (event.target.checked) {
        event.target.parentNode.parentNode.className = "selectedImg";
      } else {
        event.target.parentNode.parentNode.className = "";
      }
    },
    chooseImage() {
      document.getElementsByName("selected").forEach((rr) => {
        if (rr.checked) {
          this.selectedImg = rr.value;
          return;
        }
      });
      this.hideImg();
    },
  },
  beforeMount() {
    this.$parent.load = true;
    new Rest().getImages().then((r) => {
      this.images = r.data;
    });
    new Rest().getAllNiveaux().then((r) => {
      this.niveaux = r.data.data;
    });
  },
  mounted() {
    this.$parent.load = false;
  },
};
</script>

<style>
.hiddden{
  display: none;
}

@media screen and (max-width: 1200px) {
  #customers {
    font-size: 13px;
  }
  #customers td,
  #customers th {
    padding: 3px !important;
  }
  .toolbox {
    display: block !important;
  }
  .searchbar {
    width: fit-content !important;
    margin: 5px auto !important;
  }
}
@media screen and (max-width: 700px) {
  #customers td,
  #customers th {
    padding: 2px !important;
  }
  .Aqcm .hiddenCol {
    display: none !important;
  }
  .searchbar {
    display: block !important;
    text-align: center !important;
  }
}
.TableTitle {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: auto;
  padding-top: 20px;
  font-size: 30px;
  font-weight: bold;
}
.TableTitle img {
  margin-right: 10px;
}
.Aqcm {
  position: relative;
  height: 100%;
}
.customersCnt {
  height: calc(100% - 133px);
  max-height: calc(100% - 133px);
}
.tablesScroll {
  overflow-y: scroll;
  height: calc(100vh - 268px);
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 99.9%;
  margin: auto;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
  max-width: 30px;
  overflow-wrap: break-word;
  min-width: 30px;
  align-items: center;
}
tr {
  display: inline-table;
  width: 99.9%;
  cursor: pointer;
}
thead tr {
  display: inline-table !important;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #bb1013;
  color: white;
}
.toolbox {
  padding: 10px;
  background-color: #909090;
  display: flex;
}
.searchbar {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.searchbar input,
select {
  border-radius: 20px;
  padding: 5px;
  font-size: 15px;
}
.searchbar select {
  margin: 0 10px;
  width: 170px;
}
.searchbar input::placeholder {
  text-align: center;
}
.searchbar img {
  margin-right: 5px;
}
.btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 7px 10px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 25px;
}
.add {
  background-color: rgb(77, 214, 43);
}
.delete {
  background-color: red;
}
.edit {
  background-color: DodgerBlue;
}
.add:hover {
  background-color: #4dd62b9e;
}
.delete:hover {
  background-color: #ff00009e;
}
.edit:hover {
  background-color: #1e90ff7d;
}
.toolPanel {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ffffff85;
  z-index: 2;
  display: flex;
  align-items: center;
}
.toolPanel .cc {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  width: 50%;
  margin: auto;
  padding: 20px 50px;
  border-radius: 20px;
  position: relative;
  background-image: linear-gradient(
    to right,
    #a2c5dd,
    #8fb5d0,
    #7ca6c4,
    #6a97b7,
    #5788ab,
    #5788ab,
    #5788ab,
    #5788ab,
    #6a97b7,
    #7ca6c4,
    #8fb5d0,
    #a2c5dd
  );
}
.cc .ccTitle {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}
.cc .ccChild {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 5px;
  font-weight: bold;
}
.ccChild span {
  display: flex;
  align-items: center;
}
.ccChild input,
select {
  padding: 5px;
  font-size: 15px;
}
.ccChild textarea {
  padding: 5px;
  font-size: 15px;
  width: 100%;
}
.x {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  font-weight: bold;
  font-size: 20px;
  padding: 5px 10px;
  text-align: center;
  border-top-right-radius: 20px;
  cursor: pointer;
}
.x:hover {
  background-color: red;
}
.enable {
  cursor: no-drop;
}
.selected {
  background-color: #7777ffa1 !important;
}
.selected:hover {
  background-color: #7777ffa1 !important;
}
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 30px;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    top: 30px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
}
/* Darker background on mouse-over */
.import {
  height: fit-content;
  background-color: #ffffffb0;
  border-radius: 20px;
  border: dashed 1px;
  position: relative;
}
.import span {
  position: absolute;
  width: 100%;
  text-align: center;
  display: block;
}
.import input {
  width: 100%;
  padding: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  padding: 15px 0;
}
.child {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  max-height: 500px;
  overflow-y: auto;
}
.selectedImg {
  border: 3px solid white !important;
}
.img:hover {
  color: white !important;
}
.enabled {
  cursor: no-drop !important;
}
</style>
